import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditUrl.css';
import { ApiUrl } from '../components/Global';

const EditUrl: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [userUrl, setUserUrl] = React.useState('');

     const [urlDuplicate, setUrlDuplicate] = React.useState(false);
     const [showWarning, setShowWarning] = React.useState(false);
     const [errorMessage, setErrorMessage] = React.useState('');

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const fetchUserData = () => {

          return axios(
          {
               url: ApiUrl+'return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setUserUrl(data[0]["url"]);
          });
     }, []);

     function checkUrl(urlValue: string)
     {
          setUserUrl(urlValue);
          setShowWarning(false);
          setUrlDuplicate(false);

          if(urlValue.length < 2)
          {
               setShowWarning(true);
               setErrorMessage('Url is too short, please provide at least 2 characters.');
          }
          else if(urlValue !== "" && urlValue.match(/^[A-z0-9._-]+$/) === null)
          {
               setShowWarning(true);
               setErrorMessage('Url contains an invalid character.');
          }
          else
          {
               let bodyFormData = new FormData();
               bodyFormData.append('url', urlValue);

               return axios(
               {
                    url: ApiUrl+'check-user-data/url',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    if(response.data === "2")
                    {
                         setUrlDuplicate(true);
                         setShowWarning(true);
                         setErrorMessage('Url already exists, please choose another one.');
                    }

               });

          }
     }

     function saveData()
     {
          setSubmitForm(true);
          setShowWarning(false);

          if(urlDuplicate)
          {
               setShowWarning(true);
               setErrorMessage('Url already exists, please choose another one.');
          }
          else if(!urlDuplicate && privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('url', userUrl);

               return axios(
               {
                    url: ApiUrl+'save-user-data/url',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Get Penewo Url</h2>

                         <div style={{marginBottom: 25, background: 'linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%)', textShadow: '0 1px 0 rgba(255, 255, 255, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05)', color: '#8a6d3b', padding: 15, paddingBottom: 5, border: '1px solid #f5e79e', borderRadius: 4, fontSize: 14}}>
                              <div>Get your Penewo URL, which you can give to clients or print on your business cards.</div>
                              <br />
                              <p><b>URL can contain:</b></p>
                              <ul>
                                   <li>uppercase and lowercase letters</li>
                                   <li>numbers</li>
                                   <li>periods ( . )</li>
                                   <li>hyphen ( - )</li>
                                   <li>underscores ( _ )</li>
                              </ul>
                         </div>

                         {showWarning ? <div style={{marginBottom: 15, textAlign: 'center', background: 'linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%)', textShadow: '0 1px 0 rgba(255, 255, 255, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05)', color: '#8a6d3b', padding: 15, border: '1px solid #f5e79e', borderRadius: 4, fontSize: 14}}>{errorMessage}</div> : "" }

                         <div style={{marginTop: 6, background: '#FFF', paddingTop: 20, paddingBottom: 20}}>

                              <div className="input-group">
                                   <div className="input-group-addon">https://www.penewo.com/</div>
                                   <input type="text" className="form-control" placeholder="example-url" value={userUrl} onChange={(e) => checkUrl((e.target as HTMLInputElement).value)} />
                              </div>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditUrl;
