import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonTextarea, IonSearchbar, IonModal, IonButtons, IonButton, IonList, IonItem, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditAddress.css';
import { ApiUrl } from '../components/Global';

const EditAddress: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [ showModal, setShowModal ] = React.useState(false);

     const [building, setBuilding] = React.useState('');
     const [address, setAddress] = React.useState('');
     const [zipcode, setZipcode] = React.useState('');

     const [locationLabel, setLocationLabel] = React.useState('');
     const [locationSubLabel, setLocationSubLabel] = React.useState('');
     const [locationType, setLocationType] = React.useState('');
     const [locationId, setLocationId] = React.useState(0);

     const [searchTerm, setSearchTerm] = React.useState('');
     const [locations, setLocations] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);

     let timer = setTimeout(function(){
          console.log('timer is running');
     }, 1000*60*10);

     function searchLocation(searchValue: string)
     {
          setSearchTerm(searchValue);

          clearTimeout(timer);
          console.log('cleared - '+searchValue);

          timer = setTimeout(function()
          {
               setTimerIsSet(true);
               return axios(
               {
                    url: ApiUrl+'search-location?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    setLocations(response.data);

               });
          }, 2500);
     }



     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     console.log(privateUserId);

     const fetchUserData = () => {

          return axios(
          {
               url: ApiUrl+'return-user-data/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setBuilding(data[0]["building"]);
               setAddress(data[0]["address"]);
               setZipcode(data[0]["zip_code"]);

               setLocationLabel(data[0]["location_data"]["location_label"]);
               setLocationSubLabel(data[0]["location_data"]["location_label_sub"]);
               setLocationType(data[0]["location_data"]["location_type"]);
               setLocationId(data[0]["location_data"]["location_id"]);
          });
     }, []);

     function updateLocation(locationData:any)
     {
          console.log(locationData);

          setLocationLabel(locationData["value"]);
          setLocationSubLabel(locationData["value_sub"]);
          setLocationType(locationData["type"]);
          setLocationId(locationData["data"]);

          setShowModal(false);
     }

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('building', building);
               bodyFormData.append('address', address);
               bodyFormData.append('zip', zipcode);
               bodyFormData.append('locationId', JSON.stringify(locationId));

               return axios(
               {
                    url: ApiUrl+'save-user-data/address',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Address</h2>

                         <div onClick={() => setShowModal(true)} style={{marginTop: 10, marginLeft: 15, marginRight: 15, paddingBottom: 30, marginBottom: 30, borderBottom: '1px solid #DDD'}}>
                              <div style={{background: '#EEE', padding: 5, fontSize: 14}}>
                                   <div style={{display: 'inline-block', verticalAlign: 'top', width: '25%', fontStyle: 'italic'}}>Location: </div>
                                   <div style={{display: 'inline-block', width: '75%', borderLeft: '1px solid #AAA', paddingLeft: 6, minHeight: 35}}>
                                        <div style={{minHeight: 35}}>
                                             <span>{locationLabel}</span>
                                             <div style={{float: 'right', fontSize: 11, color: '#AAA', marginRight: 4}}>{locationType}</div>
                                             <div style={{fontSize: 11, color: '#AAA'}}>{locationSubLabel}</div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div style={{marginTop: 6, background: '#FFF', paddingBottom: 20, paddingLeft: 15, paddingRight: 15}}>



                              <IonList>

                                   <IonItem style={{marginLeft: -20, marginBottom: 25}}>
                                        <IonLabel position="stacked">Building</IonLabel>
                                        <IonInput value={building} placeholder="Enter Building Information..." onIonChange={(e) => setBuilding((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -20, marginBottom: 25}}>
                                        <IonLabel position="stacked">Address</IonLabel>
                                             <IonInput value={address} placeholder="Enter your Address..." onIonChange={(e) => setAddress((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: -20, marginBottom: 5}}>
                                        <IonLabel position="stacked">Zip Code</IonLabel>
                                             <IonInput value={zipcode} placeholder="Enter your Zip Code..." onIonChange={(e) => setZipcode((e.target as HTMLInputElement).value)}></IonInput>
                                   </IonItem>
                              </IonList>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <button onClick={() => saveData()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Save" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonCol>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>

                    <IonModal isOpen={showModal} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <div style={{width: '100%', position: 'fixed', zIndex: 10, background: '#FFF'}}>
                                   <IonSearchbar value={searchTerm} onIonChange={(e) => searchLocation((e.target as HTMLInputElement).value)} placeholder="Enter a searchterm to set your location (e.g. Miami)..." ></IonSearchbar>
                              </div>

                              <IonList style={{marginTop: 60, position: 'relative', zIndex:5}}>
                                   {
                                        locations.map(locationData => {
                                             return (
                                                  <div key={locationData["data"]} onClick={() => updateLocation(locationData)}>
                                                       <IonItem style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                            <div style={{minHeight:30, width: '100%', paddingLeft: 8, fontSize: 14}}>
                                                                 <span style={{display:'inline-block', marginBottom: 2}}><b>{locationData["value"]}</b></span>
                                                                 <div style={{float: 'right', fontSize:11, color: '#AAA', marginRight:4}}>{locationData["type"]}</div>
                                                                 <div style={{ fontSize: 11, color: '#AAA'}}>{locationData["value_sub"]}</div>
                                                            </div>
                                                       </IonItem>
                                                  </div>
                                             )
                                        })
                                   }
                              </IonList>
                         </IonContent>
                    </IonModal>

               </IonContent>
          </IonPage>
     );
};

export default EditAddress;
