import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol, IonAvatar, IonTitle, IonButton } from '@ionic/react';
import './Chats.css';
import { ApiUrl } from '../components/Global';

const Chats: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);

     const [ chats, setChats] = React.useState([]);

     const userId = localStorage.getItem('publicUserId');
     const privateUserId:any = localStorage.getItem('userId');

     const fetchChats = () => {

          let bodyFormData = new FormData();
          bodyFormData.append('privateUserId', privateUserId);

          return axios(
          {
               url: ApiUrl+'return-chats',
               method: 'post',
               data: bodyFormData,
               headers: {'Content-Type': 'multipart/form-data' }
          }).then(response => {
               console.log(response);

               return response.data;

          });
     };

     React.useEffect(() => {

          fetchChats().then(data => {
               setChats(data);
          });

     }, []);

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, paddingTop: 15}}>
                    <div style={{background: '#7386ae', color: '#e1d8b5', marginTop: 10, marginBottom: 10, paddingLeft: 15, fontSize: 14, height: 20, paddingTop: 2}}>Messages</div>
                         <div style={{marginTop: 6, background: '#FFF', paddingBottom: 20}}>

                              <div>

                                   {
                                        chats.map(chatData => {
                                             return (
                                                  <div key={chatData["id"]}>
                                                       <div style={{borderBottom: '1px solid #DDD', paddingTop: 8, paddingLeft: 10, paddingRight: 10, paddingBottom: 6}}>
                                                       	<div style={{float: 'right'}}>
                                                       		<div style={{fontSize: 12, paddingTop: 8, textAlign: 'right', minWidth: 114, paddingRight: 4, marginTop: -6}}>{chatData["last_message_date"]}</div>
                                                                 <div style={{textAlign: 'right'}}>
                                                                      <IonButton size="small" color="secondary">Block</IonButton>
                                                                      <IonButton size="small" color="danger">Delete</IonButton>
                                                                 </div>
                                                       	</div>

                                                       	<a href={"/chats/"+chatData["id"]} style={{fontSize: 12, color: '#333'}}>
                                                       		<div style={{display: 'inline-block'}}>
                                                       			<img src={chatData["participants"][0]["profile_picture"]} style={{width: 50, height: 50, border: '1px solid #EEE', float: 'left', marginRight: 6}} />
                                                       		</div>
                                                       		<div style={{display: 'inline-block', verticalAlign: 'top', paddingTop: 2}}>
                                                       			<div style={{fontSize: 12, fontWeight: 'bold', color: '#485f89'}}>{chatData["participants"][0]["name"]}</div>
                                                       			<div style={{fontSize: 12, paddingTop: 9, maxWidth: 210, height: 24, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{chatData["last_message"]}</div>
                                                       		</div>
                                                       	</a>

                                                       </div>
                                                  </div>
                                             )
                                        })
                                   }

                              </div>

                         </div>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default Chats;
