import React from 'react';
import axios from 'axios';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import { IonAlert, IonContent, IonInput, IonPage } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import './Login.css';


const Login: React.FC = () => {

     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [ showLoginAlert, setShowLoginAlert ] = React.useState(false);
     const [ username, setUsername ] = React.useState("");
     const [ password, setPassword ] = React.useState("");

     function loginUser()
     {
          setSubmitForm(true);

          let bodyFormData = new FormData();
          bodyFormData.append('username', username);
          bodyFormData.append('password', password);

          return axios(
          {
               url: ApiUrl+'login-user',
               method: 'post',
               data: bodyFormData
          }).then(response => {
               console.log(response);

               if(response.data === 0)
               {
                    setSubmitForm(false);
                    setShowLoginAlert(true);
               }
               else
               {
                    localStorage.setItem('loggedIn', "1");
                    localStorage.setItem('publicUserId', response.data.users_id);
                    localStorage.setItem('userId', response.data.private_user_id);
                    localStorage.setItem('userName', response.data.name);
                    localStorage.setItem('userUrl', response.data.url);
                    localStorage.setItem('userEmail', response.data.email);
                    localStorage.setItem('userAvatar', response.data.users_id+"/"+response.data.profile_picture.replace('.jpg', '_100.jpg'));
                    document.location.href = "/home";
               }
          })
     }

     return (
          <IonPage>
               <IonContent>

                    <div style={{marginTop: 75, marginLeft: 15, marginRight: 15}}>
                         <h3 style={{fontSize: 26, textAlign: 'center', borderBottom: '1px solid #DDD', borderTop: '1px solid #DDD', paddingTop: 4, paddingBottom: 4, background: '#EEE', color: '#6c90b3', marginBottom: 22}}>Login</h3>

                         <div style={{paddingLeft: 15, paddingRight: 15}}>
                              <div style={{marginBottom: 20}}>
                                   <IonInput name="username" type="text" value={username} onIonChange={(e) => setUsername((e.target as HTMLInputElement).value)} placeholder="Username" style={{height: 34, paddingTop: 6, paddingBottom: 6, paddingLeft: 12, paddingRight: 12, fontSize: 14, width: '100%', border: 0, background: 'transparent', borderBottom: '1px solid #CCC'}} />
                              </div>

                              <div style={{marginBottom: 30}}>
                                   <IonInput type="password" name="password" value={password} onIonChange={(e) => setPassword((e.target as HTMLInputElement).value)} placeholder="Password" style={{height: 34, paddingTop: 6, paddingBottom: 6, paddingLeft: 12, paddingRight: 12, fontSize: 14, width: '100%', border: 0, background: 'transparent', borderBottom: '1px solid #CCC'}} />
                              </div>

                              <div style={{textAlign: 'center'}}>
                                   <button onClick={() => loginUser()} style={{height: 32, background: '#6c90b3',  border: '1px solid #AAA', paddingTop: 5, paddingBottom: 5, width: '30%', color: '#FFF', fontSize: 14, verticalAlign: 'top'}}>{(!submitForm) ? "Login" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>

                                   <div style={{height: 32, display: 'inline-block', background: '#F6F6F6', border: '1px solid #EEE', paddingTop: 5, paddingBottom: 5, width: '70%', fontSize: 14, color: '#6c90b3', verticalAlign: 'top'}}>Forgot your Password?</div>
                              </div>

                              <div style={{marginBottom: 25, marginTop: 45, marginLeft: 20, marginRight: 20}}>
          					<div style={{width: '40%', borderTop: '1px solid #DDD', display: 'inline-block', verticalAlign: 'top', float: 'left', marginLeft: '2%'}}></div>
          					<div style={{width: '16%', textAlign: 'center', display: 'inline-block', verticalAlign: 'top', marginTop: -7, color: '#AAA'}}>OR</div>
          					<div style={{width: '40%', borderTop: '1px solid #DDD', display: 'inline-block', verticalAlign: 'top', float: 'right', marginRight: '2%'}}></div>
          				</div>

                              <h3 style={{fontSize: 17, textAlign: 'center', color: '#888', marginBottom: 28}}>connect using your social account</h3>

                              <div style={{textAlign: 'center', color: '#AAA', marginBottom: 20}}>
          					<img src="./assets/icon/icon-facebook-grey.png" style={{marginLeft: 6, marginRight: 6, width: 25}} />
                                   <img src="./assets/icon/icon-google-grey.png" style={{marginLeft: 6, marginRight: 6, width: 25}} />
          				</div>

                              <hr style={{marginTop: 20, marginBottom: 20, borderTop: '1px solid #eee'}} />


                         </div>

                         <div style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 15, paddingRight: 15, background: '#eee', borderTop: '1px solid #DDD'}}>
                              <h4 style={{fontSize: 17, textAlign: 'center', color: '#6c90b3', marginBottom: 20}}>don't have an account yet?</h4>

                              <a href="https://dev.penewo.com/register" style={{color: '#FFF', textDecoration: 'none'}}><div style={{height: 32, textAlign: 'center', background: '#6c90b3', paddingTop: 7, paddingBottom: 5, paddingLeft: 32, paddingRight: 32, border: '1px solid #AAA', fontSize: 14}}>Register</div></a>
                         </div>
                    </div>

                    <IonAlert
                         isOpen={showLoginAlert}
                         onDidDismiss={() => setShowLoginAlert(false)}
                         header={'Login Failed'}
                         message={'Please review your login info and retry.'}
                         buttons={['OK']}
                    />

               </IonContent>
          </IonPage>
  );
};

export default Login;
