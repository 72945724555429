import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { IonModal, IonButtons, IonButton, IonContent, IonIcon, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import { desktop, mail, call } from 'ionicons/icons';
import './AboutUs.css';


const AboutUs: React.FC = () => {

     const [userData, setUserData] = React.useState([]);
     const [showModalInfo, setShowModalInfo] = React.useState(false);
     const [showModalMicro, setShowModalMicro] = React.useState(false);
     const [showModalNamecard, setShowModalNamecard] = React.useState(false);
     const [showModalWebpage, setShowModalWebpage] = React.useState(false);
     const [showModalMainMovie, setShowModalMainMovie] = React.useState(false);
     const [showModalMeetJane, setShowModalMeetJane] = React.useState(false);

     React.useEffect(() => {

     }, []);

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{marginTop:80}}>
                         <div style={{fontStyle: "italic", textTransform: "uppercase"}}>
                              <div style={{textAlign: "center", padding: "40px 0 20px", margin: "0 50px", borderBottom: "1px solid #AAA"}}>
                                   <img src="https://dev.penewo.com/images/logo_thumb_light.png?t=1" style={{width: "50px"}} />
                              </div>

                              <div style={{textTransform: "uppercase", fontWeight: "bold", fontSize: "14px", color: "#3c688b", textAlign: "center", paddingTop: "5px"}}>Description Movies</div>

                              <div style={{textAlign: "center", paddingTop: "35px"}}>
                                   <table style={{margin: "0 auto"}}>
                                        <tr>
                                             <td onClick={() => setShowModalInfo(true)} style={{borderRight: "1px solid #888", borderBottom: "1px solid #888", padding: "8px 18px", color: "#777", fontSize: "14px"}}><img src="https://dev.penewo.com/images/logos/penewo-logo-menu-1.png" style={{maxWidth: "90px"}} /><br />Info - Feed</td>
                                             <td onClick={() => setShowModalMicro(true)} style={{borderLeft: "1px solid #888", borderBottom: "1px solid #888", padding: "8px 18px", color: "#777", fontSize: "14px"}}><img src="https://dev.penewo.com/images/logos/penewo-logo-menu-2.png" style={{maxWidth: "90px"}} /><br />Micro</td>
                                        </tr>
                                        <tr>
                                             <td onClick={() => setShowModalNamecard(true)} style={{borderRight: "1px solid #888", padding: "8px 18px", color: "#777", fontSize: "14px"}}><img src="https://dev.penewo.com/images/logos/penewo-logo-menu-3.png" style={{maxWidth: "90px"}} /><br />E - Name Card</td>
                                             <td onClick={() => setShowModalWebpage(true)} style={{borderLeft: "1px solid #888", padding: "8px 18px", color: "#777", fontSize: "14px"}}><img src="https://dev.penewo.com/images/logos/penewo-logo-menu-4.png" style={{maxWidth: "90px"}} /><br />Free Webpage</td>
                                        </tr>
                                   </table>
                              </div>

                              <div style={{padding: "0 15px"}}>
                                   <div onClick={() => setShowModalMainMovie(true)} style={{margin: "50px 15px 0", textAlign: "left", padding: "0 8px"}}>
                                        <div style={{display: "inline-block", width: "10px", height: "10px", background: "#e0e020", verticalAlign: "middle", marginRight: "15px"}}></div>
                                        <div style={{display: "inline-block", verticalAlign: "middle", fontStyle: "italic", fontWeight: "bold", color: "#3c688b", textTransform: "uppercase", fontSize: "13px"}}>Main promotional movie</div>
                                   </div>

                                   <div onClick={() => setShowModalMeetJane(true)} style={{fontStyle: "italic", margin: "30px 15px 50px", padding: "0 8px"}}>
                                        <div style={{display: "inline-block", width: "10px", height: "10px", background: "#e0e020", verticalAlign: "middle", marginRight: "15px"}}></div>
                                        <div style={{display: "inline-block", verticalAlign: "middle", fontStyle: "italic", fontWeight: "bold", color: "#3c688b", textTransform: "uppercase", fontSize: "13px"}}>Get frienships that matter<br />(meet Jane)</div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <IonModal isOpen={showModalInfo} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalInfo(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/MGqjp1rDwJc"></iframe>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalMicro} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalMicro(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/nJ40Md-MSls"></iframe>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalNamecard} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalNamecard(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/TaGtwSFpKG8"></iframe>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalWebpage} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalWebpage(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/1FfUGPE1yyQ"></iframe>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalMainMovie} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalMainMovie(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/jIMVVnETYnU"></iframe>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalMeetJane} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalMeetJane(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <iframe style={{width: 560, height: 315, background: "rgba(0,0,0,0.4)"}} src="https://www.youtube.com/embed/fFA06J-LjEs"></iframe>
                         </div>
                    </IonModal>
               </IonContent>
          </IonPage>
  );
};

export default AboutUs;
