import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonActionSheet, IonList, IonItem, IonAvatar, IonCheckbox, IonLabel } from '@ionic/react';
import './NewsFeedSample.css';

const NewsFeedSample: React.FC = () => {

     const [userData, setUserData] = React.useState([]);

     const[newsFeedFilter_keywordsOn, setNewsFeedFilter_keywordsOn] = React.useState("1");
     const[newsFeedFilter_friendsOn, setNewsFeedFilter_friendsOn] = React.useState("1");
     const[newsFeedFilter_followsOn, setNewsFeedFilter_followsOn] = React.useState("1");



     const [ showActionSheet, setShowActionSheet ] = React.useState(false);
     const [ showModalKeywordSuggestions, setShowModalKeywordSuggestions ] = React.useState(false);
     const [ showModalKeywords, setShowModalKeywords ] = React.useState(false);
     const [ showModalFriends, setShowModalFriends ] = React.useState(false);
     const [ showModalFollows, setShowModalFollows ] = React.useState(false);

     const [newsFeedItems, setNewsFeedItems] = React.useState([
     	{name: 'Bill Drox', profilePicture: 'https://dev.penewo.com/images/news-feed/user_0.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_0.jpg'},
     	{name: 'Petra Scholl', profilePicture: 'https://dev.penewo.com/images/news-feed/user_1.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_1.jpg'},
     	{name: 'Jen Pliss', profilePicture: 'https://dev.penewo.com/images/news-feed/user_2.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_2.jpg'},
     	{name: 'Monica Dull', profilePicture: 'https://dev.penewo.com/images/news-feed/user_3.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_3.jpg'},
          {name: 'Bill Drox', profilePicture: 'https://dev.penewo.com/images/news-feed/user_0.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_4.jpg'},
     	{name: 'Petra Scholl', profilePicture: 'https://dev.penewo.com/images/news-feed/user_1.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_5.jpg'},
     	{name: 'Jen Pliss', profilePicture: 'https://dev.penewo.com/images/news-feed/user_2.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_6.jpg'},
     	{name: 'Monica Dull', profilePicture: 'https://dev.penewo.com/images/news-feed/user_3.jpg', upload: 'https://dev.penewo.com/images/news-feed/upload_7.jpg'},
     ]);

     function setFilterKeywordActiveState(isSet: any)
     {
          setNewsFeedFilter_keywordsOn(isSet);
     }

     function setFilterFriendActiveState(isSet: any)
     {
          setNewsFeedFilter_friendsOn(isSet);
     }

     function setFilterFollowsActiveState(isSet: any)
     {
          setNewsFeedFilter_followsOn(isSet);
     }

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{marginTop:65}}>
                         <div>
                              <IonGrid>
                                   <IonRow style={{background: '#FFF', position: 'relative', zIndex: 100}}>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => (newsFeedFilter_keywordsOn === "1") ? setFilterKeywordActiveState("0") : setFilterKeywordActiveState("1")} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Your <br />Interests</div>
                                                  <div className={(newsFeedFilter_keywordsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => (newsFeedFilter_friendsOn === "1") ? setFilterFriendActiveState("0") : setFilterFriendActiveState("1")} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 10, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Friends</div>
                                                  <div className={(newsFeedFilter_friendsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => (newsFeedFilter_followsOn === "1") ? setFilterFollowsActiveState("0") : setFilterFollowsActiveState("1")} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Your <br />Follows</div>
                                                  <div className={(newsFeedFilter_followsOn === "1") ? "activeSwitch" : "inactiveSwitch"}></div>
                                             </div>
                                        </IonCol>
                                        <IonCol style={{paddingLeft: 4, paddingRight: 4}}>
                                             <div onClick={() => setShowModalKeywordSuggestions(true)} style={{position: 'relative', display: 'block', textAlign: 'center', height: 30, textDecoration: 'none', width: '100%', paddingTop: 4, textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', background: '#7386ae', color: '#FFF'}}>
                                                  <div style={{display: 'inline-block'}}>Interests <br />Suggestions</div>
                                             </div>
                                        </IonCol>
                                   </IonRow>
                              </IonGrid>
                         </div>
                         <div>
                              {
                                   newsFeedItems.map(newsFeedItem => {
                                        return (
                                             <div style={{marginBottom: 30, paddingLeft: 15, paddingRight: 15, position: 'relative'}}>

                                                  <div style={{background: '#FAFAFA', height: 45, paddingTop: 5, borderTop: '1px solid #F3F3F3'}}>
                                                       <div style={{float: 'left', marginRight: 10, height: 40, zIndex: 5, position: 'relative'}}>
                                                            <img src={newsFeedItem["profilePicture"]} style={{width: 65, height:65, background: '#FFF', border: '1px solid #FFF'}} />
                                                       </div>
                                                       <div>
                                                            <div style={{fontSize: 16, paddingTop: 8, color: '#AAA'}}>{newsFeedItem["name"]}</div>
                                                       </div>

                                                  </div>

                                                  <IonButton color="dark" onClick={() => setShowActionSheet(true)} expand="block" style={{backgroundColor: '#7386ae', width: 50, height: 50, position: 'absolute', textAlign: 'center', top: 46, right: 17, zIndex: 100, borderRadius: 100, opacity: 0.7, border: '2px solid #FFFFFF', padding: 1, overflow: 'hidden'}}>
                                                       <img src="./assets/icon/more-menu.png" style={{maxWidth: '200%'}} />
                                                  </IonButton>

                                                  <img src={newsFeedItem["upload"]} />
                                                  <div style={{marginBottom: 10, backgroundColor: '#eeeeee', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, textAlign: 'left', fontSize: 14}}>Sample Upload</div>
                                                  <div style={{marginBottom: 10, paddingLeft: 15, textAlign: 'left', fontSize: 12}}>This is a sample upload to demonstrate how newsfeed uploads look like</div>
                                             </div>
                                        )
                                   })
                              }
                         </div>
                    </div>

                    <IonModal isOpen={showModalKeywordSuggestions} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Interests Suggestions</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalKeywordSuggestions(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>

                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalKeywords} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Keyword Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalKeywords(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <IonButton color="primary" expand="full" size="small" style={{marginBottom: 15, height: 35}}>Add Area of Interest</IonButton>
                              <p style={{marginLeft: 10, fontSize: 13}}>Set On/Off</p>
                              <IonList>

                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalFriends} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Friends Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalFriends(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <p style={{marginLeft: 10, fontSize: 13}}>Set On/Off</p>
                              <IonList>

                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonModal isOpen={showModalFollows} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>Follows Settings</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalFollows(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <p style={{marginLeft: 10, fontSize: 13}}>Set On/Off</p>
                              <IonList>

                              </IonList>
                         </IonContent>
                    </IonModal>

                    <IonActionSheet
                         isOpen={showActionSheet}
                         onDidDismiss={() => setShowActionSheet(false)}
                         buttons={[{
                              text: 'Like',
                              handler: () => {
                                   console.log('Delete clicked');
                              }
                         }, {
                              text: 'Share',
                              handler: () => {
                                   console.log('Share clicked');
                              }
                         }, {
                              text: 'Comments',
                              handler: () => {
                                   console.log('Play clicked');
                              }
                         }, {
                              text: 'Download',
                              handler: () => {
                                   console.log('Favorite clicked');
                              }
                         }, {
                              text: 'Cancel',
                              role: 'cancel',
                              handler: () => {
                                   console.log('Cancel clicked');
                              }
                         }]}
                    ></IonActionSheet>

               </IonContent>
          </IonPage>
  );
};

export default NewsFeedSample;
