import React from 'react';
import axios from 'axios';
import { IonContent, IonModal, IonHeader, IonTitle, IonToolbar, IonSearchbar, IonButtons, IonButton, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import './EditKeywords.css';
import { ApiUrl } from '../components/Global';

const EditKeywords: React.FC = () => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [keywordCategories, setKeywordCategories] = React.useState([]);
     const [keywords, setKeywords] = React.useState([]);
     const [showModal, setShowModal] = React.useState(false);

     const [keywordsList, setKeywordsList] = React.useState([]);

     const [modalHeader, setModalHeader] = React.useState("");
     const [categoryId, setCategoryId] = React.useState(0);

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');

     const [searchTerm, setSearchTerm] = React.useState('');
     const [locations, setLocations] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);

     let timer = setTimeout(function(){
          console.log('timer is running');
     }, 1000*60*10);

     let currentCategory = "";

     function searchKeyword(searchValue: string)
     {
          setSearchTerm(searchValue);

          clearTimeout(timer);
          console.log('cleared - '+searchValue);

          timer = setTimeout(function()
          {
               return axios(
               {
                    url: ApiUrl+'search-keyword?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    setKeywordsList(response.data);

               });
          }, 2500);
     }

     const fetchKeywordCategories = () => {

          return axios(
          {
               url: ApiUrl+'return-keyword-categories',
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchUserKeywords = () => {

          return axios(
          {
               url: ApiUrl+'return-user-keywords/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchKeywordCategories().then(data => {
               setKeywordCategories(data);
          });


          fetchUserKeywords().then(data => {
               setKeywords(data);
          });
     }, []);

     function saveData()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);

               return axios(
               {
                    url: ApiUrl+'save-user-data/contact',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile/edit";

               });
          }
     }

     function addKeyword(tid: number)
     {
          if(privateUserId !== null)
          {
               setShowModal(false);

               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('tid', JSON.stringify(tid));
               bodyFormData.append('termCategory', JSON.stringify(categoryId));

               return axios(
               {
                    url: ApiUrl+'save-user-data/add-keyword',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    fetchUserKeywords().then(data => {
                         setKeywords(data);
                    });

               });
          }
     }

     function removeKeyword(tid: number, termCategory: number)
     {
          if(privateUserId !== null)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append('tid', JSON.stringify(tid));
               bodyFormData.append('termCategory', JSON.stringify(termCategory));

               return axios(
               {
                    url: ApiUrl+'save-user-data/remove-keyword',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response);

                    fetchUserKeywords().then(data => {
                         setKeywords(data);
                    });

               });
          }
     }

     function showKeywordModal(categoryType:number, headerLabel:string)
     {
          setCategoryId(categoryType);
          setModalHeader(headerLabel);
          setShowModal(true)
     }

     function returnKeywordLayout()
     {
          let keywordOutput = []
          let currentCategory = "";

          keywordOutput.push('');

          keywordCategories.map(categoryData => {
               if(currentCategory === "" || categoryData["category_type"] !== currentCategory)
               {
                    currentCategory = categoryData["category_type"];
                    keywordOutput.push(
                              <div style={{margin: '30px 15px 5px', fontWeight: 'bold', paddingLeft: 5}}>{categoryData["category_type"]}</div>
                    );
               }

               keywordOutput.push(
                    <div style={{margin: '0 15px 15px'}}>
                         <div onClick={() => showKeywordModal(categoryData["id"], categoryData["label"])} style={{background: categoryData["color_scheme"], color:'#FFF', padding: '2px 10px', height: 20, marginBottom:5}}>
                              {categoryData["label"]}
                              <div style={{float: 'right', fontWeight: 'bold', fontSize: 20, marginTop: -3}}>+</div>
                         </div>
                    </div>
               )

               keywords.map(keywordData => {
                    if(keywordData["name"] === categoryData["category_name"])
                    {
                         for (let keywordItem of keywordData["keyword_items"])
                         {
                              keywordOutput.push(
                                   <div style={{color: '#999', marginLeft: 25, marginRight: 25, marginTop: -6, marginBottom: 20, fontSize:13}}><div onClick={() => removeKeyword(keywordItem["tid"], keywordItem["term_category"])} style={{float: 'right', fontSize: 22}}>&times;</div>{keywordItem["name"]}</div>
                              )
                         }
                    }
               })
          });

          return keywordOutput;
     }

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Areas of Interest</h2>
                         <div style={{marginTop: 6, background: '#FFF', paddingBottom: 20, fontSize: 14}}>
                              <div>
                                   { returnKeywordLayout() }
                              </div>

                         </div>

                         <IonGrid>
                              <IonRow style={{marginTop: 20}}>
                                   <IonCol style={{padding: 0}}>
                                        <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Close</a>
                                   </IonCol>
                              </IonRow>
                         </IonGrid>
                    </div>

                    <IonModal isOpen={showModal} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonTitle>{modalHeader}</IonTitle>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <IonContent>
                              <div style={{width: '100%', position: 'fixed', zIndex: 10, background: '#FFF'}}>
                                   <IonSearchbar value={searchTerm} onIonChange={(e) => searchKeyword((e.target as HTMLInputElement).value)}  placeholder="search by area of interest..."></IonSearchbar>
                              </div>

                              <IonList style={{marginTop: 60, position: 'relative', zIndex:5}}>
                                   {
                                        keywordsList.map(keywordData => {
                                             return (
                                                  <div key={keywordData["data"]} onClick={() => addKeyword(keywordData["data"])}>
                                                       <IonItem key={keywordData["data"]} style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                            <IonLabel style={{paddingLeft: 10}}>{keywordData["value"]}</IonLabel>
                                                       </IonItem>
                                                  </div>
                                             )
                                        })
                                   }
                              </IonList>
                         </IonContent>
                    </IonModal>

               </IonContent>
          </IonPage>
     );
};

export default EditKeywords;
