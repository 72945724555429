import React from 'react';
import axios from 'axios';
import { IonContent, IonInput, IonLabel, IonTextarea, IonCheckbox, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol, IonToast } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import './AddUpload.css';

const AddUpload: React.FC = () => {
     const [ uploadForm, setUploadForm ] = React.useState(false);
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [ fileUploaded, setFileUploaded ] = React.useState(false);
     const [ showUploadError, setShowUploadError ] = React.useState(false);

     const [uploadPath, setUploadPath] = React.useState('');
     const [uploadFile, setUploadFile] = React.useState('https://via.placeholder.com/1024x768/222222/ffffff?text=Upload%20Preview');
     const [userKeywords, setUserKeywords] = React.useState({"business": [], "life": [], "science": []});

     const [uploadTitle, setUploadTitle] = React.useState('');
     const [uploadDescription, setUploadDescription] = React.useState('');

     const [businessChecked, setBusinessChecked] = React.useState(false);
     const [lifeChecked, setLifeChecked] = React.useState(false);
     const [scienceChecked, setScienceChecked] = React.useState(false);

     const [uploadPreviewClass, setUploadPreviewClass] = React.useState('form-shown');
     const [uploadInfoClass, setUploadInfoClass] = React.useState('form-hidden');

     const [uploadKeywords, setUploadKeywords] = React.useState(new Array());
     let tempUploadKeywords = new Array();

     const userId = localStorage.getItem('publicUserId');
     const privateUserId = localStorage.getItem('userId');


     const fetchUserData = () => {

          return axios(
          {
               url: ApiUrl+'return-user-keywords?privateUserId='+privateUserId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => {
               setUserKeywords(data);
          });
     }, []);

     function changeKeywordStatus(keywordId:number, keywordState:boolean)
     {
          if(keywordState)
          {
               tempUploadKeywords.push(keywordId);
          }
          else
          {
               for(var i=0;i<tempUploadKeywords.length;i++)
               {
                    if(tempUploadKeywords[i] === keywordId)
                    {
                         tempUploadKeywords.splice(i, 1);
                    }
               }
          }

          console.log(tempUploadKeywords);

          setUploadKeywords(tempUploadKeywords);
     }

     function checkKeyword(keywordType:string, keywordId:number)
     {
          if(keywordType === "business")
          {
               if(businessChecked || tempUploadKeywords.indexOf(keywordId) !== -1)
               {
                    return true;
               }
               else
               {
                    return false;
               }
          }
          else if(keywordType === "life")
          {
               if(lifeChecked || tempUploadKeywords.indexOf(keywordId) !== -1)
               {
                    return true;
               }
               else
               {
                    return false;
               }
          }
          else if(keywordType === "science")
          {
               if(scienceChecked || tempUploadKeywords.indexOf(keywordId) !== -1)
               {
                    return true;
               }
               else
               {
                    return false;
               }
          }
     }

     function returnKeywordLayout(keywordType:string)
     {
          let keywordOutput = []

          if(keywordType === "business")
          {
               keywordOutput.push(<div style={{background: '#337ab7', fontSize: 14, color:'#FFF', marginLeft: -5, marginRight: -5, padding: 5}}>Business</div>);

               userKeywords.business.map(keywordData => {
                    keywordOutput.push(
                              <div style={{marginLeft: -15}}>
                                   <IonItem>
                                        <IonLabel>{keywordData["name"]}</IonLabel>
                                        <IonCheckbox color="success" slot="start"  checked={checkKeyword('business', keywordData["tid"])} onIonChange={e => changeKeywordStatus(keywordData["tid"], e.detail.checked)}   />
                                   </IonItem>
                              </div>
                    )
               });

               return keywordOutput;
          }
          else if(keywordType === "life")
          {
               keywordOutput.push(<div style={{background: '#5cb85c', fontSize: 14, color:'#FFF', marginLeft: -5, marginRight: -5, padding: 5}}>Life</div>);

               userKeywords.life.map(keywordData => {
                    keywordOutput.push(
                         <div style={{marginLeft: -15}}>
                              <IonItem>
                                   <IonLabel>{keywordData["name"]}</IonLabel>
                                   <IonCheckbox color="success" slot="start"  checked={checkKeyword('life', keywordData["tid"])} onIonChange={e => changeKeywordStatus(keywordData["tid"], e.detail.checked)} />
                              </IonItem>
                         </div>
                    )
               });

               return keywordOutput;
          }
          else if(keywordType === "science")
          {
               keywordOutput.push(<div style={{background: '#d9534f', fontSize: 14, color:'#FFF', marginLeft: -5, marginRight: -5, padding: 5}}>Science</div>);

               userKeywords.science.map(keywordData => {
                    keywordOutput.push(
                         <div style={{marginLeft: -15}}>
                              <IonItem>
                                   <IonLabel>{keywordData["name"]}</IonLabel>
                                   <IonCheckbox color="success" slot="start"  checked={checkKeyword('science', keywordData["tid"])} onIonChange={e => changeKeywordStatus(keywordData["tid"], e.detail.checked)} />
                              </IonItem>
                         </div>
                    )
               });

               return keywordOutput;
          }
     }

     function uploadFileServer(selectorFiles: FileList)
     {
          setUploadForm(true);

          if(privateUserId !== null)
          {
               console.log(selectorFiles);
               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append("file", selectorFiles[0]);

               return axios(
               {
                    url: ApiUrl+'upload-document',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    setUploadForm(false);

                    setUploadFile(response.data["url"]);
                    setUploadPath(response.data["path"]);
                    setFileUploaded(true);

               });
          }
     }

     function formNext()
     {
          if(fileUploaded)
          {
               setUploadPreviewClass('form-hidden');
               setUploadInfoClass('form-shown');
          }
          else
          {
               setShowUploadError(true);
          }
     }

     function saveUpload()
     {
          setSubmitForm(true);

          if(privateUserId !== null)
          {
               console.log(uploadKeywords);

               let bodyFormData = new FormData();
               bodyFormData.append('privateUserId', privateUserId);
               bodyFormData.append("interests", JSON.stringify(uploadKeywords));
               bodyFormData.append("path", uploadPath);
               bodyFormData.append("type", "image");
               bodyFormData.append("title", uploadTitle);
               bodyFormData.append("description", uploadDescription);

               return axios(
               {
                    url: ApiUrl+'save-upload',
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
               }).then(response => {
                    console.log(response);

                    document.location.href = "/my-profile";

               });
          }
     }


     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, paddingTop: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>New Upload</h2>
                         <div className={uploadPreviewClass} style={{marginTop: 6, background: '#FFF', paddingTop: 15, paddingBottom: 10}}>

                              <div style={{textAlign: 'center', marginBottom: 20}}>
                                   <input type="file" onChange={ (e) => uploadFileServer(e.target.files as FileList) } style={{border: '1px solid #AAA', borderRadius: 5}} />
                                   <br /><br />
                                   {(!uploadForm) ? "" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: 30, background: '#AAA', borderRadius: 50}} />}
                              </div>

                              <div>
                                   <img src={uploadFile} />
                              </div>

                              <IonGrid>
                                   <IonRow style={{marginTop: 20}}>
                                        <IonCol style={{padding: 0}}>
                                             <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                        </IonCol>
                                        <IonCol style={{padding: 0}}>
                                             <button onClick={() => formNext()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Next" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                        </IonCol>
                                   </IonRow>
                              </IonGrid>

                         </div>

                         <div className={uploadInfoClass} style={{marginTop: 6, background: '#FFF', paddingTop: 15, paddingBottom: 10, paddingLeft: 15, paddingRight: 15}}>

                              <div>
                                   <div style={{paddingRight: 15}}>
                                        <IonList>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Upload Title</IonLabel>
                                                  <IonInput value={uploadTitle} onIonChange={(e) => setUploadTitle((e.target as HTMLInputElement).value)}></IonInput>
                                             </IonItem>

                                             <IonItem style={{marginLeft: -5, marginBottom: 25}}>
                                                  <IonLabel position="stacked">Upload Description</IonLabel>
                                                  <IonTextarea rows={4} value={uploadDescription} onIonChange={(e) => setUploadDescription((e.target as HTMLInputElement).value)}></IonTextarea>
                                             </IonItem>
                                        </IonList>
                                   </div>

                                   <p style={{fontSize: 14}}>Set who can see it by areas of interest (business people don't want to see your poodle).</p>

                                   <div>
                                        <IonGrid style={{marginLeft: -10, marginRight: -10}}>
                                             <IonRow>
                                                  <IonCol style={{padding: 0}}>
                                                       <div onClick={() => {(!businessChecked) ? setBusinessChecked(true) : setBusinessChecked(false); }} style={{background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', borderColor: '#245580', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}><IonCheckbox color="light" checked={businessChecked} style={{float: 'left', verticalAlign: 'top', marginTop: -5, marginLeft: 6}} /> Business</div>
                                                  </IonCol>
                                                  <IonCol style={{padding: 0}}>
                                                       <div onClick={() => {(!lifeChecked) ? setLifeChecked(true) : setLifeChecked(false); }} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}><IonCheckbox color="light" checked={lifeChecked} style={{float: 'left', verticalAlign: 'top', marginTop: -5, marginLeft: 6}} /> Life</div>
                                                  </IonCol>
                                                  <IonCol style={{padding: 0}}>
                                                       <div onClick={() => {(!scienceChecked) ? setScienceChecked(true) : setScienceChecked(false); }} style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}}><IonCheckbox color="light" checked={scienceChecked} style={{float: 'left', verticalAlign: 'top', marginTop: -5, marginLeft: 6}} /> Science</div>
                                                  </IonCol>
                                             </IonRow>
                                        </IonGrid>

                                        { (userKeywords.business.length > 0) ? returnKeywordLayout("business") : "" }
                                        { (userKeywords.life.length > 0) ? returnKeywordLayout("life") : "" }
                                        { (userKeywords.science.length > 0) ? returnKeywordLayout("science") : "" }

                                   </div>


                              </div>

                              <IonGrid>
                                   <IonRow style={{marginTop: 20}}>
                                        <IonCol style={{padding: 0}}>
                                             <a style={{background: 'linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%)', borderColor: '#b92c28', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, paddingTop: 9, textAlign: 'center', textDecoration: 'none', fontSize: 14}} href="my-profile/edit">Cancel</a>
                                        </IonCol>
                                        <IonCol style={{padding: 0}}>
                                             <button onClick={() => saveUpload()} style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Next" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                        </IonCol>
                                   </IonRow>
                              </IonGrid>
                         </div>


                    </div>

                    <IonToast
                         isOpen={showUploadError}
                         onDidDismiss={() => setShowUploadError(false)}
                         message="Please choose a file to upload first"
                         duration={1800}
                         color="danger"
                         buttons={[
                              {
                                   text: 'X',
                                   role: 'cancel',
                                   handler: () => {
                                        console.log('Cancel clicked');
                                   }
                              }
                         ]}
                    />
               </IonContent>
          </IonPage>
     );
};

export default AddUpload;
