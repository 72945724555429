import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './EditOverview.css';

const EditOverview: React.FC = () => {
     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <h2 style={{textAlign: 'center', marginBottom:20}}>Edit Profile</h2>
                         <ul className="list-group">
                              <li className="list-group-item"><a href="my-profile/edit/aboutMe" style={{display: 'block'}}>About Me <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/profilePicture" style={{display: 'block'}}>Profile Picture <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/featured" style={{display: 'block'}}>Edit and Upload More Pictures in Profile <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/contact" style={{display: 'block'}}>Contact Info <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/address" style={{display: 'block'}}>Address <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/map" style={{display: 'block'}}>Map <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/keywords" style={{display: 'block'}}>Areas Of Interest <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/micro" style={{display: 'block'}}>Name Card <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/password" style={{display: 'block'}}>Change Password <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                              <li className="list-group-item"><a href="my-profile/edit/url" style={{display: 'block'}}>Get Your Penewo Url <span style={{float: 'right'}}><img src="./assets/icon/icon-chevron-right.png" style={{maxHeight: 16}} /></span></a></li>
                         </ul>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default EditOverview;
