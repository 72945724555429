import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonFooter } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import './SearchKeyword.css';

const SearchKeyword: React.FC = () => {
     const [searchTerm, setSearchTerm] = React.useState('');
     const [keywords, setKeywords] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);
     const [showLoading, setShowLoading] = React.useState(false);
     //const [timer, setTimer] = React.useState({});

     let timer = setTimeout(function(){
          console.log('timer is running');
     }, 1000*60*10);

     function searchKeyword(searchValue: string)
     {
          setShowLoading(true);
          setKeywords([]);
          setSearchTerm(searchValue);

          if(searchValue === "")
          {
               setShowLoading(false);
          }

          clearTimeout(timer);
          console.log('cleared - '+searchValue);

          timer = setTimeout(function()
          {
               return axios(
               {
                    url: ApiUrl+'search-keyword?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    if(searchTerm === searchValue)
                    {
                         setShowLoading(false);
                         setKeywords(response.data);
                    }

               });
          }, 2500);
     }

     return (
          <IonPage>
               <IonHeader>
                    <IonToolbar>
                         <div style={{marginTop: 70, paddingTop: 10}}>
                              <IonSearchbar value={searchTerm} onIonChange={(e) => searchKeyword((e.target as HTMLInputElement).value)}  placeholder="search by area of interest..."></IonSearchbar>
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent>

                    {(showLoading) ? <div style={{textAlign: 'center', marginTop: 30}}><img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: 40, background: '#AAA', borderRadius: 50}} /></div> : ''}

                    <IonList>
                         {
                              keywords.map(keywordData => {
                                   return (
                                        <a href={"search/result/"+keywordData["url"]+"/all"} style={{textDecoration: 'none'}}>
                                             <IonItem key={keywordData["data"]} style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                  <IonLabel style={{paddingLeft: 10}}>{keywordData["value"]}</IonLabel>
                                             </IonItem>
                                        </a>
                                   )
                              })
                         }
                    </IonList>



               </IonContent>
          </IonPage>
     );
};

export default SearchKeyword;
