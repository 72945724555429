import React from 'react';
import axios from 'axios';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonAvatar, IonFooter } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import './SearchName.css';

const SearchName: React.FC = () => {
     const [searchTerm, setSearchTerm] = React.useState('');
     const [users, setUsers] = React.useState([]);
     const [timerIsSet, setTimerIsSet] = React.useState(false);
     const [timer, setTimer] = React.useState({});

     function SearchName(searchValue: string)
     {
          setSearchTerm(searchValue);

          if(timerIsSet)
          {

               setTimerIsSet(false);
          }

          setTimer(setTimeout(function()
          {
               setTimerIsSet(true);
               return axios(
               {
                    url: ApiUrl+'search-name?term='+searchValue,
                    method: 'get'
               }).then(response => {
                    console.log(response);

                    setUsers(response.data);

               });
          }, 1500))
     }

     return (
          <IonPage>
               <IonHeader>
                    <IonToolbar>
                         <div style={{marginTop: 70, paddingTop: 10}}>
                              <IonSearchbar value={searchTerm} onIonChange={(e) => SearchName((e.target as HTMLInputElement).value)}  placeholder="search by name..."></IonSearchbar>
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent>

                    <IonList>
                         {
                              users.map(userData => {
                                   return (
                                        <a href={"profile/"+userData["user_id"]} style={{textDecoration: 'none'}}>
                                             <IonItem key={userData["user_id"]} style={{paddingLeft: 0, marginLeft: -10, marginRight: 10}}>
                                                  <IonAvatar slot="start">
                                                       <img src={userData["profile_picture"]} />
                                                  </IonAvatar>
                                                  <IonLabel>{userData["name"]}</IonLabel>
                                             </IonItem>
                                        </a>
                                   )
                              })
                         }
                    </IonList>

               </IonContent>
          </IonPage>
     );
};

export default SearchName;
