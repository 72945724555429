import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { IonModal, IonButtons, IonButton, IonContent, IonIcon, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import { desktop, mail, call } from 'ionicons/icons';
import './Profile.css';

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}

const Profile: React.FC<UserDetailPageProps> = ({match}) => {

     const [userData, setUserData] = React.useState([]);
     const [featuredUploadPath, setFeaturedUploadPath] = React.useState('');
     const [showModal, setShowModal] = React.useState(false);

     const [uploads, setUploads] = React.useState([{
          path: "test",
          upload_type: "image",
          poster: "test"
     }]);

     const fetchUserData = () => {

          return axios(
          {
               url: ApiUrl+'return-user-data/'+match.params.id,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchUploads = () => {

          return axios(
          {
               url: ApiUrl+'return-user-uploads-preview/'+match.params.id+'/6',
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     React.useEffect(() => {
          fetchUserData().then(data => setUserData(data));
          fetchUploads().then(data => setUploads(data));
     }, []);

     function showFeaturedUpload(featuredUploadPathValue:string)
     {
          setFeaturedUploadPath(featuredUploadPathValue);
          setShowModal(true);
     }

     function RenderFeaturedUploads() {
          let imageOutput = [];

          for (let featuredUpload of userData[0]['featured_uploads'])
          {
               // console.log(featuredUpload);
               imageOutput.push(
                    <div onClick={() => showFeaturedUpload(featuredUpload["path"])} style={{display: 'inline-block', width: 56, height: 56, marginLeft:1, marginRight: 1, border: '1px solid #AAAAAA', backgroundPosition: 'center center', backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundSize: 'cover'}}></div>
               )
          }

          return imageOutput;
     }

     function RenderUploads() {
          let imageOutput = [];

          for (let upload of uploads)
          {
               // console.log(featuredUpload);
               if(upload && upload.upload_type == "video")
               {
                    imageOutput.push(
                         <div style={{padding: "15px", marginBottom: "15px", background: "#FFF"}}>
                              <video poster={upload["poster"]} controls style={{maxWidth: '100%', maxHeight: '42vh', marginTop: 5, flex: 1}}>
                                   <source src={upload["path"]} type="video/mp4"></source>
                              </video>
                         </div>
                    )
               }
               else
               {
                    imageOutput.push(
                         <div onClick={() => showFeaturedUpload(upload["path"])} style={{padding: "15px", marginBottom: "15px", background: "#FFF"}}><img src={upload["path"]} style={{maxWidth: '100%'}} /></div>
                    )
               }
          }

          return imageOutput;
     }

     function RenderKeywordLayout() {
          let keywordOutput = []

          for (let keywordGroup of userData[0]["keywords"])
          {
               let children = []
               for (let keywordItem of keywordGroup["keyword_items"])
               {
                    children.push(
                         <li style={{color: '#999999', marginLeft: 5, marginRight: 5, marginBottom: 8, fontSize: 14, listStyleType: 'none'}}>{keywordItem["name"]}</li>
                    )
               }

               keywordOutput.push(
                    <div className="keywordHeader" style={{marginLeft:15, marginRight:15, marginBottom: 15}}>
                         <div className={keywordGroup['name']} style={{color:'#FFFFFF', paddingTop: 1, paddingLeft: 10, height: 20, marginBottom:5}}>
                              <span>{keywordGroup['label']}</span>
                         </div>
                         <ul style={{paddingLeft: 4}}>{children}</ul>
                    </div>
               )
          }

          return keywordOutput;
     }

     function RenderMap() {

          if(userData[0]["coordinates"] && userData[0]["coordinates"] !== "")
          {
               let latitude = userData[0]["latitude"];
               let longitude = userData[0]["longitude"];

               return (
                    <div>
                         <img style={{cursor: "pointer", position: "relative", margin: "0", width: "100vw"}} src={"https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCpAoRV-3199y7CWQbS9iBMkSHTYHQibYQ&center="+latitude+","+longitude+"&zoom=7&scale=false&size=550x410&maptype=roadmap&format=png&visual_refresh=true&markers=color:red%7C"+latitude+","+longitude} />
                    </div>
               )
          }
     }

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{marginTop:80}}>
                    {
                         userData.map(userInfo => {

                              if(userInfo['is_profile'] !== 0)
                              {
                                   return (
                                        <div key={userInfo["id"]}>

                                             <div>
                                             <IonGrid style={{padding: 0}}>
                                                  <IonRow style={{padding: 0}}>
                                                       <IonCol style={{padding: 0, marginRight: -6, maxWidth: 150}}>
                                                            <img src={userInfo['profile_picture']} style={{width: 150}} />
                                                       </IonCol>
                                                       <IonCol style={{padding: 0}}>
                                                            <div style={{backgroundColor: '#859fc4', height: 150, paddingTop: 10, position: 'relative'}}>
                                                                 <div style={{background: 'linear-gradient(to bottom, #17406b, #5783ad)', color: '#FFFFFF', fontWeight: 'bold', padding: 5, marginBottom: 2, height: 45}}>{userInfo['name']}</div>
                                                                 <div style={{paddingLeft:10}}>
                                                                      <div style={{color: '#FFFFFF', fontSize:14, letterSpacing: 0.8, paddingTop: 2, paddingBottom: 2}}>{userInfo['city_name']}</div>
                                                                      <div style={{color: '#FFFFFF', fontSize:14, letterSpacing: 0.8, paddingTop: 2, paddingBottom: 2}}>{userInfo['country_name']}</div>
                                                                 </div>

                                                                 <div style={{position:'absolute', bottom:0, left:2, right:2}}>
                                                                 <IonGrid style={{padding: 0}}>
                                                                      <IonRow style={{padding: 0}}>
                                                                           <IonCol style={{paddingLeft: 2, paddingRight: 2}}>
                                                                                <div style={{background: '#697a9b', height: 32, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', paddingTop: 9, paddingBottom: 3, borderRadius: 4, border: '1px solid #f3df9c', color: '#f3df9c', fontSize: 10}}>
                                                                                     <a href={"/profile/"+userInfo['id']+"/share"} style={{color: '#f3df9c', textDecoration: 'none'}}>
                                                                                     Namecard
                                                                                     </a>
                                                                                </div>
                                                                           </IonCol>
                                                                           <IonCol style={{paddingLeft: 2, paddingRight: 2}}>
                                                                                <div style={{background: '#697a9b', height: 32, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', paddingTop: 9, paddingBottom: 3, borderRadius: 4, border: '1px solid #f3df9c', color: '#f3df9c', fontSize: 10}}>
                                                                                     Reviews
                                                                                </div>
                                                                           </IonCol>
                                                                           <IonCol style={{paddingLeft: 2, paddingRight: 2}}>
                                                                                <div style={{background: '#697a9b', height: 32, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', paddingTop: 9, paddingBottom: 3, borderRadius: 4, border: '1px solid #FFFFFF', color: '#FFFFFF', fontSize: 10}}>
                                                                                     <a href={"/profile/"+userInfo['id']+"/uploads"} style={{color: '#FFFFFF', textDecoration: 'none'}}>
                                                                                     Uploads
                                                                                     </a>
                                                                                </div>
                                                                           </IonCol>
                                                                      </IonRow>
                                                                 </IonGrid>
                                                                 </div>
                                                            </div>
                                                       </IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                             </div>

                                             <div style={{backgroundColor: '#F2F2F2', marginTop: 20, paddingTop:10}}>
                                                  <div style={{textAlign: 'center', marginBottom: 15}}>
                                                       {RenderFeaturedUploads()}
                                                  </div>
                                                  <div>
                                                       <div style={{backgroundColor: '#7386ae', color: '#e1d8b5', marginBottom: 20, paddingLeft:30, paddingTop: 3, paddingBottom: 3, fontSize:16}}>
                                                            About Me
                                                       </div>
                                                       <div style={{paddingLeft: 30, paddingRight: 30, paddingBottom:15}}>
                                                            <div style={{paddingBottom: 6, borderBottom: '1px solid #bbbbbb', marginBottom: 15, color: '#333333', fontSize: 18, fontWeight: 'bold'}}>
                                                                 {userInfo['quote']}
                                                            </div>
                                                            <div style={{marginLeft: 3, fontSize: 15, whiteSpace: 'pre-line'}}>{userInfo['description']}</div>
                                                       </div>
                                                  </div>
                                                  <div>
                                                       <div style={{backgroundColor: '#7386ae', color: '#e1d8b5', marginBottom: 20, paddingLeft:30, paddingTop: 3, paddingBottom: 3, fontSize:16}}>
                                                            Areas Of Interest
                                                       </div>
                                                       <div>
                                                            {RenderKeywordLayout()}
                                                       </div>
                                                  </div>
                                                  <div style={{marginBottom: "20px"}}>
                                                       <div style={{backgroundColor: '#7386ae', color: '#e1d8b5', marginBottom: 20, paddingLeft:30, paddingTop: 3, paddingBottom: 3, fontSize:16}}>
                                                            Contact
                                                       </div>
                                                       <div>
                                                            <div>
                                                                 <div style={{marginBottom: 10}}>
                                                                      <div style={{color: '#466289', fontSize: 14, paddingLeft: 30, paddingRight: 30, marginBottom: 15}}>
                                                                           {(userInfo['building'] !== "") ? <div>{userInfo['building']}</div> : "" }
                                                                           <div>{userInfo['address']}</div>
                                                                           <div>{userInfo['city_name']}</div>
                                                                           <div>{userInfo['country_name']}</div>
                                                                      </div>
                                                                      <div style={{color: '#466289', fontSize: 14, paddingLeft: 30, paddingRight: 30, marginBottom: 15}}>
                                                                           {(userInfo['website'] !== "") ? <div><IonIcon icon={desktop} style={{marginRight: 4, verticalAlign: 'top', marginTop: 1}} /> {userInfo['website']}</div> : "" }
                                                                           {(userInfo['email'] !== "") ? <div><IonIcon icon={mail} style={{marginRight: 4, verticalAlign: 'top', marginTop: 1}} /> {userInfo['email']}</div> : "" }
                                                                           {(userInfo['phone'] !== "") ? <div><IonIcon icon={call} style={{marginRight: 4, verticalAlign: 'top', marginTop: 1}} /> {userInfo['phone']}</div> : "" }
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div>
                                                            <IonGrid>
                                                                 <IonRow>
                                                                      {(userInfo['facebook'] !== "") ?
                                                                           <IonCol>
                                                                                <div style={{textAlign: 'center'}}>
                                                                                     <a href={userInfo['facebook']} target="_blank">
                                                                                          <div style={{width: 40, height: 40, display: 'inline-block', backgroundColor: '#3b5998', padding: 5}}>
                                                                                               <img src="./assets/icon/icon-facebook.png" />
                                                                                          </div>
                                                                                     </a>
                                                                                </div>
                                                                           </IonCol>
                                                                      : "" }
                                                                      {(userInfo['twitter'] !== "") ?
                                                                           <IonCol>
                                                                                <div style={{textAlign: 'center'}}>
                                                                                     <a href={userInfo['twitter']} target="_blank">
                                                                                          <div style={{width: 40, height: 40, display: 'inline-block', backgroundColor: '#1da1f2', padding: 5}}>
                                                                                               <img src="./assets/icon/icon-twitter.png" />
                                                                                          </div>
                                                                                     </a>
                                                                                </div>
                                                                           </IonCol>
                                                                      : "" }
                                                                      {(userInfo['linkedin'] !== "") ?
                                                                           <IonCol>
                                                                                <div style={{textAlign: 'center'}}>
                                                                                     <a href={userInfo['linkedin']} target="_blank">
                                                                                          <div style={{width: 40, height: 40, display: 'inline-block', backgroundColor: '#1884bb', padding: 5}}>
                                                                                               <img src="./assets/icon/icon-linkedin.png" />
                                                                                          </div>
                                                                                     </a>
                                                                                </div>
                                                                           </IonCol>
                                                                      : "" }
                                                                 </IonRow>
                                                            </IonGrid>
                                                       </div>
                                                       {(userInfo['coordinates'] !== "") ?
                                                            <>
                                                                 {RenderMap()}
                                                            </>
                                                       : "" }
                                                  </div>
                                                  <div>
                                                       <div style={{backgroundColor: '#7386ae', color: '#e1d8b5', marginBottom: 20, paddingLeft:30, paddingTop: 3, paddingBottom: 3, fontSize:16}}>
                                                            Latest Uploads
                                                       </div>
                                                       <div>
                                                            {RenderUploads()}
                                                       </div>
                                                       <div style={{padding: "5px 25px"}}>
                                                            <IonButton href={"/profile/"+userInfo['id']+"/uploads"} expand="block" fill="outline">See All Uploads</IonButton>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )
                              }
                         })
                    }
                    </div>
                    <IonModal isOpen={showModal} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <img src={featuredUploadPath} style={{width: '100%', position: 'absolute', top: 44}} />
                    </IonModal>
               </IonContent>
          </IonPage>
  );
};

export default Profile;
