import React from 'react';
import axios from 'axios';
import { IonContent, IonIcon, IonHeader, IonModal, IonButtons, IonButton, IonPage, IonTitle, IonToolbar, IonSlides, IonSlide, IonList, IonItem, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewWillEnter, CreateAnimation, createGesture, Gesture,  } from '@ionic/react';
import { ellipse, square, caretBackOutline, caretUpOutline, caretDownOutline, triangle, home, person, archive, chatbubbles, apps, card, pin, star, pricetag, business, book } from 'ionicons/icons';
import { Animation } from '@ionic/core';
import ExploreContainer from '../components/ExploreContainer';
import Advert from '../components/Advert';
import { ApiUrl } from '../components/Global';
import './Home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/react/css/ionic-swiper.css';


const fetchUsers = () => {

     return axios(
     {
          url: ApiUrl+'latest-users',
          method: 'get'
     }).then(response => {
          return response.data;
     })
};

const fetchRummageTerms = () => {

     return axios(
     {
          url: ApiUrl+'rummagebox-terms',
          method: 'get'
     }).then(response => {
          return response.data;
     })
};

const Home: React.FC = () => {

     const tentRefs = React.useRef<(HTMLDivElement | null)[]>([])

     const [users, setUsers] = React.useState([]);
     const [terms, setTerms] = React.useState([]);

     const [showModal, setShowModal] = React.useState(false);

     const [currentMicroAnimation, setCurrentMicroAnimation] = React.useState(0);

     const [showUploadsPreview, setShowUploadsPreview] = React.useState(false);

     const [uploadsPreviewData, setUploadsPreviewData] = React.useState({
          user_id: '61',
          name: 'Charles Dubones',
          profile_picture: 'https://dev.penewo.com/users/62/23ca206952259c18951a2de80d2cfb40_250.jpg',
          keyword: 'Project Management',
          color: '#33486c'
     });

     const fetchRummageBoxItems = (categories: any) => {

          return axios(
          {
               url: ApiUrl+'rummage-box-items?category[]='+categories,
               method: 'get'
          }).then(response => {
               console.log(response);
               return response.data;
          })
     };

     const [uploadsPreviews, setUploadsPreviews] = React.useState([{
          id: 543,
          upload_type: "video",
          path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
          upload_path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
          poster: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
          title: "Web Page",
          description: "Web Page"
     }]);

     const [categories, setCategories]= React.useState([]);

     const [showGestureAnimation, setShowGestureAnimation] = React.useState(true);
     const [showPreviewGestureAnimation, setShowPreviewGestureAnimation] = React.useState(true);



     React.useEffect(() => {

          fetchUsers().then(data => {
               setUsers(data);

          });
          fetchRummageTerms().then(data =>
          {
               const termsData = data.map((item: any) => {
                    return {...item, active: false};
               });

               setTerms(termsData);
          });

          setTimeout(function()
          {
               setShowGestureAnimation(false);
          }, 6000)

     }, []);

     function SetCategoriesState(categoryId: any)
     {

          const termsData: any = terms.map((item: any) => {
               if(item.id == categoryId && !item.active)
               {
                    return {...item, active: true};
               }
               else if(item.id == categoryId && item.active)
               {
                    return {...item, active: false};
               }
               else
               {
                    return item;
               }
          });

          setTerms(termsData);
          RefreshRummageBox(termsData);
     }

     function RefreshRummageBox(termsData: any)
     {
          var categories = new Array();

          termsData.map((term: any) =>{
               if(term.active)
               {
                    categories.push(term.name)
               }
          });

          if(categories.length > 0)
          {
               fetchRummageBoxItems(categories.join('&category[]=')).then(data => {
                    setUsers(data);

               });
          }
          else
          {
               fetchUsers().then(data => {
                    setUsers(data);

               });
          }
     }

     const [touchMicroStartX, setTouchMicroStartX] = React.useState(0);
     const [touchMicroEndX, setTouchMicroEndX] = React.useState(0);
     const [touchMicroStartTime, setTouchMicroStartTime] = React.useState(0);
     const [touchMicroEndTime, setTouchMicroEndTime] = React.useState(0);

     const microAnimationTimer = 600;

     function HandleMicroTouchStart(touchStartEvent:any) {
          var currentTime = new Date();

          setTouchMicroStartX(touchStartEvent.targetTouches[0].clientX);
          setTouchMicroStartTime(currentTime.getTime());
     }

     function HandleMicroTouchMove(touchMoveEvent:any) {
          var currentTime = new Date();

          setTouchMicroEndX(touchMoveEvent.targetTouches[0].clientX);
          setTouchMicroEndTime(currentTime.getTime());
     }

     function HandleMicroTouchEnd(userId:any) {
          if(touchMicroEndX < touchMicroStartX && (touchMicroEndX+80) < touchMicroStartX && (touchMicroEndTime-touchMicroStartTime) < 400 && (touchMicroEndTime-touchMicroStartTime) > 50)
          {
               setCurrentMicroAnimation(userId)

               setTimeout(function(){
                    ShowUserUploadPreview(userId);
                    setCurrentMicroAnimation(0)
               }, microAnimationTimer);
          }
     }


     const [previewAnimationSwipeUp, setPreviewAnimationSwipeUp] = React.useState(false);
     const [previewAnimationSwipeDown, setPreviewAnimationSwipeDown] = React.useState(false);

     const [touchStartY, setTouchStartY] = React.useState(0);
     const [touchEndY, setTouchEndY] = React.useState(0);
     const [touchStartTime, setTouchStartTime] = React.useState(0);
     const [touchEndTime, setTouchEndTime] = React.useState(0);

     const previewAnimationTimer = 800;

     function HandleTouchStart(touchStartEvent:any) {
          var currentTime = new Date();

          setTouchStartY(touchStartEvent.targetTouches[0].clientY);
          setTouchStartTime(currentTime.getTime());
     }

     function HandleTouchMove(touchMoveEvent:any) {
          var currentTime = new Date();

          setTouchEndY(touchMoveEvent.targetTouches[0].clientY);
          setTouchEndTime(currentTime.getTime());
     }

     function HandleTouchEnd() {

          if(touchEndY < touchStartY && (touchEndY+80) < touchStartY && (touchEndTime-touchStartTime) < 400 && (touchEndTime-touchStartTime) > 50)
          {
               setPreviewAnimationSwipeUp(true);

               setTimeout(function()
               {
                    var userCount = 0;
                    users.map(userInfo => {
                         if(userInfo['id'] == uploadsPreviewData.user_id && userCount < users.length)
                         {
                              let userData = {
                                   user_id: users[(userCount+1)]['id'],
                                   name: users[(userCount+1)]['name'],
                                   profile_picture: users[(userCount+1)]['profile_picture'],
                                   keyword: users[(userCount+1)]['keyword']['name'],
                                   color: users[(userCount+1)]["micro_color_end"]
                              };

                              setUploadsPreviewData(userData);

                              setUploadsPreviews([{
                                   id: 543,
                                   upload_type: "video",
                                   path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   upload_path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   poster: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   title: "Web Page",
                                   description: "Web Page"
                              }]);

                              setUploadsPreviews(users[(userCount+1)]["uploads"]);
                         }

                         userCount++;
                    })
               }, (previewAnimationTimer/4));

               setTimeout(function()
               {
                    setPreviewAnimationSwipeUp(false);
               }, previewAnimationTimer);
          }

          if(touchEndY > touchStartY && touchEndY > (touchStartY+80) && (touchEndTime-touchStartTime) < 400 && (touchEndTime-touchStartTime) > 50)
          {
               setPreviewAnimationSwipeDown(true);

               setTimeout(function()
               {
                    var userCount = 0;
                    users.map(userInfo => {
                         if(userInfo['id'] == uploadsPreviewData.user_id && userCount > 0)
                         {
                              let userData = {
                                   user_id: users[(userCount-1)]['id'],
                                   name: users[(userCount-1)]['name'],
                                   profile_picture: users[(userCount-1)]['profile_picture'],
                                   keyword: users[(userCount-1)]['keyword']['name'],
                                   color: users[(userCount-1)]["micro_color_end"]
                              };

                              setUploadsPreviewData(userData);

                              setUploadsPreviews([{
                                   id: 543,
                                   upload_type: "video",
                                   path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   upload_path: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   poster: "https://dev.penewo.com/users/21/uploads/2021/10/thumb_162e5b9a8361bed76a0e80a33016150a.jpg",
                                   title: "Web Page",
                                   description: "Web Page"
                              }]);

                              setUploadsPreviews(users[(userCount-1)]["uploads"]);
                         }

                         userCount++;
                    })
               }, (previewAnimationTimer/4));

               setTimeout(function()
               {
                    setPreviewAnimationSwipeDown(false);
               }, previewAnimationTimer);
          }
     }

     function ShowUserUploadPreview(userId:string) {
          setShowModal(true);
          setShowPreviewGestureAnimation(true);

          users.map(userInfo => {
               if(userInfo['id'] == userId)
               {
                    let userData = {
                         user_id: userInfo['id'],
                         name: userInfo['name'],
                         profile_picture: userInfo['profile_picture'],
                         keyword: userInfo['keyword']['name'],
                         color: userInfo["micro_color_end"]
                    };

                    setUploadsPreviewData(userData);
                    setUploadsPreviews(userInfo["uploads"]);
               }
          })

          setTimeout(function()
          {
               setShowPreviewGestureAnimation(false);
          }, 3500)
     }

     function RenderFeaturedUploads(userId:string, uploads:any) {
          let imageOutput = [];
          let imageId = 0;

          for (let featuredUpload of uploads)
          {
               imageOutput.push(
                    <div onClick={() => ShowUserUploadPreview(userId)} style={{display: 'inline-block', width: 51, height: 51, border: '1px solid #FFF', backgroundPosition: 'center center', backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundSize: 'cover'}}></div>
               )

               imageId++;
          }

          return imageOutput;
     }

     var itemCount = 0;

     return (
          <IonPage style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
               <IonContent>
                    <div style={{paddingBottom: "15px", margin: "105px 0 20px", display: 'flex', flexWrap: 'nowrap', position: 'relative', overflowX: 'auto'}}>
                         {
                              terms.map(term => {

                                   return (
                                        <div onClick={() => SetCategoriesState(term["id"])} key={term["id"]} style={{width: 87, flex: '0 0 auto', float: 'left', whiteSpace: 'nowrap', textAlign: 'center', fontSize: 11}}>
                                             <div style={{padding: 0, textDecoration: 'none'}}>
                                                  { (term["active"]) ?
                                                       <img src={term['image']} style={{width: 82, marginTop: '-5px', marginBottom: 5, display: 'block'}} />
                                                       :
                                                       <img src={term['image']} style={{width: 82, marginTop: '-5px', marginBottom: 5, display: 'block', filter: 'grayscale(100%)'}} />
                                                  }
                                                  <div>{term['label']}</div>
                                                  <div style={{fontSize: 9}}>{term['type']}</div>
                                             </div>
                                        </div>
                                   );
                              })
                         }
                    </div>

                    <div style={{fontSize: 13, paddingLeft: 3}}>Latest Members:</div>
                    <div>

                         {
                              users.map(userInfo => {
                                   itemCount++;
                                        return (
                                             <div>
                                                  <div>
                                                       <CreateAnimation
                                                            duration={microAnimationTimer}
                                                            keyframes={[
                                                                 { offset: 0, transform: 'translateX(0)' },
                                                                 { offset: 0.7, transform: 'translateX(-180px)' },
                                                                 { offset: 1, transform: 'translateX(0)' }
                                                            ]}
                                                            easing="ease-out" play={(currentMicroAnimation == userInfo["id"]) ? true : false} >

                                                            <div className="micro" key={userInfo["id"]} onTouchStart={touchStartEvent  => HandleMicroTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => HandleMicroTouchMove(touchMoveEvent)} onTouchEnd={() => HandleMicroTouchEnd(userInfo["id"])} style={{marginBottom: 40, position: "relative"}} ref={(ref) => { tentRefs.current.push(ref) }}>
                                                                 { (userInfo["id"] == 21 && showGestureAnimation) ?
                                                                           <div style={{position: 'absolute', right: 0, top: 0, bottom: '-5px', width: "120px", paddingTop: "60px", textAlign: "center", background: "rgba(255,255,255,0.8)", zIndex: 10}}>
                                                                                <CreateAnimation
                                                                                     iterations={Infinity}
                                                                                     duration={1800}
                                                                                     keyframes={[
                                                                                          { offset: 0, transform: 'translateX(0)' },
                                                                                          { offset: 0.2, transform: 'translateX(0)' },
                                                                                          { offset: 0.4, transform: 'translateX(-30px)' },
                                                                                          { offset: 0.5, transform: 'translateX(0)' },
                                                                                          { offset: 0.6, transform: 'translateX(-15px)' },
                                                                                          { offset: 0.8, transform: 'translateX(0)' },
                                                                                          { offset: 1, transform: 'translateX(0)' }
                                                                                     ]}
                                                                                     easing="ease-in" play={true} >
                                                                                     <IonIcon icon={caretBackOutline} style={{fontSize: 40, marginBottom: 3, color: '#D00', opacity: '0.6'}} />
                                                                                </CreateAnimation>
                                                                                <div style={{ fontWeight: "bold", fontSize: "14px", color: "#000"}}>Swipe Left<br />For Uploads</div>
                                                                           </div>
                                                                       : ""
                                                                 }
                                                                 <div style={{padding: 0, marginBottom: 0, height: 190, position: 'relative', width: '100%'}}>
                                                                      <a href={"/profile/"+userInfo["id"]} style={{padding: 0, textDecoration: 'none'}}>
                                                                           <div style={{color: '#ffffff', fontSize: 15, marginTop: 5, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 6, backgroundColor: userInfo["micro_color_end"], borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF', textAlign: 'center'}}>
                                                                                { userInfo["country_name"]
                                                                                     ? <div><div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div><div style={{fontSize: 11, marginBottom: 3}}>{userInfo['country_name']}, {userInfo['city_name']}</div></div>
                                                                                     : <div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div>
                                                                                }
                                                                           </div>
                                                                           <div style={{margin:0, padding: 0, overflow: 'visible', borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF'}}>
                                                                                <div style={{float: 'left'}}>
                                                                                     <img src={userInfo['profile_picture']} style={{width:110, marginRight: 10, marginBottom: -6}} />
                                                                                </div>
                                                                                <div style={{backgroundColor: '#859fc4', height: 110, marginLeft: 110, position: 'relative'}}>
                                                                                     <div style={{overflow: 'hidden', paddingRight: 10, paddingTop: 8}}>
                                                                                          <div style={{color: '#FFFFFF', fontSize: 16, marginRight: 10, fontStyle: 'italic', height: 56, letterSpacing: 1, whiteSpace: 'pre-line', overflow: 'hidden'}}>{userInfo['micro_content']}</div>

                                                                                     </div>
                                                                                     <div style={{ position: 'absolute', bottom: 5, fontSize: 13, color: '#FFFFFF', paddingLeft: 10, paddingTop: 5}}>
                                                                                          <div style={{color: '#FFFFFF', marginBottom: 2}}>{userInfo['keyword']['label']}</div>
                                                                                          <div style={{color: '#FFFFFF'}}>{userInfo['keyword']['name']}</div>
                                                                                     </div>
                                                                                </div>

                                                                           </div>
                                                                      </a>
                                                                      <div style={{backgroundColor: '#7386ae', height: 51, padding: 0, whiteSpace: 'nowrap', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#CCCCCC'}}>
                                                                           {RenderFeaturedUploads(userInfo["id"], userInfo["uploads"])}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </CreateAnimation>

                                                  </div>
                                                  { Number.isInteger(itemCount/3) &&
                                                       <Advert />
                                                  }
                                             </div>
                                        );
                              })
                         }
                         <Advert />
                    </div>
                    <IonModal isOpen={showModal} showBackdrop={false}>
                         <div style={{backgroundColor: "#FFF"}}>
                              { (showPreviewGestureAnimation) ?
                                   <>
                                        <div style={{position: "fixed", top: 0, left: 0, right: 0, width: "100%", background: "rgba(255,255,255,0.9)", height: "82px", paddingTop: "20px", display: "flex", alignItems: "center", flexDirection: "column", zIndex: 10}}>
                                             <CreateAnimation
                                                  iterations={Infinity}
                                                  duration={1800}
                                                  keyframes={[
                                                       { offset: 0, transform: 'translateY(0)' },
                                                       { offset: 0.2, transform: 'translateY(0)' },
                                                       { offset: 0.4, transform: 'translateY(-30px)' },
                                                       { offset: 0.5, transform: 'translateY(0)' },
                                                       { offset: 0.6, transform: 'translateY(-15px)' },
                                                       { offset: 0.8, transform: 'translateY(0)' },
                                                       { offset: 1, transform: 'translateY(0)' }
                                                  ]}
                                             easing="ease-in" play={true} >
                                                  <div style={{lineHeight: 0, display: "inline-block"}}>
                                                       <IonIcon icon={caretUpOutline} style={{fontSize: 40, marginBottom: 3, color: '#D00', opacity: '0.6'}} />
                                                  </div>
                                             </CreateAnimation>
                                             <p style={{margin: 0, fontWeight: "bold"}}>
                                                  Swipe Up For Previous Profile
                                             </p>
                                        </div>

                                        <div style={{position: "fixed", bottom: 0, left:0, right:0, width: "100%", background: "rgba(255,255,255,0.9)", height: "86px", display: "flex", alignItems: "center", flexDirection: "column", zIndex: 10, paddingTop: "10px"}}>
                                             <p style={{margin: 0, fontWeight: "bold"}}>
                                                  Swipe Down For Next Profile
                                             </p>
                                             <CreateAnimation
                                                  iterations={Infinity}
                                                  duration={1800}
                                                  keyframes={[
                                                       { offset: 0, transform: 'translateY(0)' },
                                                       { offset: 0.2, transform: 'translateY(0)' },
                                                       { offset: 0.4, transform: 'translateY(30px)' },
                                                       { offset: 0.5, transform: 'translateY(0)' },
                                                       { offset: 0.6, transform: 'translateY(15px)' },
                                                       { offset: 0.8, transform: 'translateY(0)' },
                                                       { offset: 1, transform: 'translateY(0)' }
                                                  ]}
                                             easing="ease-in" play={true} >
                                                  <div style={{lineHeight: 0, display: "inline-block"}}>
                                                       <IonIcon icon={caretDownOutline} style={{fontSize: 40, marginBottom: 3, color: '#D00', opacity: '0.6'}} />
                                                  </div>
                                             </CreateAnimation>
                                        </div>
                                   </>
                              : "" }
                                   <CreateAnimation
                                        duration={previewAnimationTimer}
                                        keyframes={[
                                             { offset: 0, transform: 'translateY(0)' },
                                             { offset: 0.5, transform: 'translateY(-100vh)' },
                                             { offset: 0.5, transform: 'translateY(100vh)' },
                                             { offset: 1, transform: 'translateY(0)' }
                                        ]}
                                   easing="ease-out" play={previewAnimationSwipeUp} >
                                        <div>
                                             <CreateAnimation
                                                  duration={previewAnimationTimer}
                                                  keyframes={[
                                                       { offset: 0, transform: 'translateY(0)' },
                                                       { offset: 0.5, transform: 'translateY(100vh)' },
                                                       { offset: 0.5, transform: 'translateY(-100vh)' },
                                                       { offset: 1, transform: 'translateY(0)' }
                                                  ]}
                                             easing="ease-out" play={previewAnimationSwipeDown} >
                                                  <div style={{padding: '20px 0 24px', background: '#FFF'}}>
                                                        <div style={{height: '50px', paddingLeft: '8px', position: 'absolute', top: '20px', left: 0, right: 0, background: uploadsPreviewData.color}}>
                                                             <div style={{display: 'inline-block'}}>
                                                                  <a href={"/profile/"+uploadsPreviewData.user_id} style={{textDecoration: 'none'}}>
                                                                       <img src={uploadsPreviewData.profile_picture} style={{height: '80px', marginTop: '-15px', marginBottom: '-15px', border: '1px solid #DDD'}} />
                                                                  </a>
                                                             </div>
                                                             <div style={{display: 'inline-block', position: 'absolute', left: '88px', right: 0, top: 0, bottom: 0}}>
                                                                  <div style={{display: 'inline-block', width: '76%', padding: '7px 5px 5px', position: 'relative', height: '50px', whiteSpace: 'nowrap'}}>
                                                                       <a href={"/profile/"+uploadsPreviewData.user_id} style={{textDecoration: 'none'}}>
                                                                            <h2 style={{fontSize: '16px', margin: 0, fontWeight: 'normal', color: '#fff'}}>{uploadsPreviewData.name}</h2>
                                                                            <span style={{color: '#FFF'}}>{uploadsPreviewData.keyword}</span>
                                                                       </a>
                                                                  </div>

                                                                  <div onClick={() => setShowModal(false)} style={{display: 'inline-block', width: '22%', height: '100%', background: '#7386ae', float: 'right', textAlign: 'center', color:'#f3df9c', fontSize: '12px', lineHeight: '15px', padding: '3px 0', position: 'relative'}}>
                                                                       <div>
                                                                            <img src="https://dev.penewo.com/images/logo_thumb_gold.png" style={{maxHeight: '20px', margin: '3px 0 0'}} />
                                                                      </div>
                                                                       back
                                                                  </div>
                                                             </div>
                                                       </div>
                                                       <div style={{marginTop: "70px"}} onTouchStart={touchStartEvent  => HandleTouchStart(touchStartEvent)} onTouchMove={touchMoveEvent => HandleTouchMove(touchMoveEvent)} onTouchEnd={() => HandleTouchEnd()}>
                                                            <Swiper modules={[Pagination, Navigation]} pagination={true} navigation={true}>
                                                                 { uploadsPreviews.map(uploadsPreview => {
                                                                      return (
                                                                           <SwiperSlide key={uploadsPreview.id}>
                                                                                <div style={{background: "#929292", width: "100%", height: "calc(100vh - 70px)", overflow: "auto"}}>
                                                                                     { (uploadsPreview.upload_type == "video") ?
                                                                                          <video key={uploadsPreview.id} poster={uploadsPreview.poster} controls style={{maxWidth: '100%', maxHeight: '42vh', marginTop: 5, flex: 1}}>
                                                                                               <source src={uploadsPreview.upload_path} type="video/mp4"></source>
                                                                                          </video>
                                                                                          :
                                                                                          <img src={uploadsPreview.upload_path} style={{maxWidth: '100%', marginBottom: '-7px'}} />
                                                                                     }
                                                                                     <div style={{margin: "0 0 10px", background: "#666a6d", padding: "8px 0", color: "#FFF", fontSize: "14px"}}>
                                                                                          {uploadsPreview.title}
                                                                                     </div>
                                                                                     <div style={{margin: "0 0 10px", fontSize: "13px", color: "#FFF", paddingBottom: "20px"}}>
                                                                                          {uploadsPreview.description}
                                                                                     </div>
                                                                                </div>
                                                                           </SwiperSlide >
                                                                      );
                                                                 })}
                                                            </Swiper>
                                                       </div>
                                                  </div>
                                             </CreateAnimation>
                                        </div>
                              </CreateAnimation>
                         </div>
                    </IonModal>
               </IonContent>
          </IonPage>
     );
};

export default Home;
