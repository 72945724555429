import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import { IonContent, IonIcon, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonSlides, IonSlide, IonActionSheet, IonButton } from '@ionic/react';
import { desktop, mail, call } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import './RegisterNotice.css';


const RegisterNotice: React.FC = () => {

     return (
          <IonPage>
               <IonContent>

                    <div style={{marginTop: 100, marginLeft: 20, marginRight: 20}}>
                         <a href="/login" style={{height: 34, textDecoration: 'none', paddingTop: 6, fontSize: 14, textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', color: '#FFFFFF', textAlign: 'center', border: '1px solid #245580', display: 'block', width: '100%'}}>Login</a>
                    </div>

                    <div style={{marginBottom: 25, marginTop: 45, marginLeft: 20, marginRight: 20}}>
					<div style={{width: '40%', borderTop: '1px solid #DDD', display: 'inline-block', verticalAlign: 'top', float: 'left', marginLeft: '2%'}}></div>
					<div style={{width: '16%', textAlign: 'center', display: 'inline-block', verticalAlign: 'top', marginTop: -7, color: '#AAA'}}>OR</div>
					<div style={{width: '40%', borderTop: '1px solid #DDD', display: 'inline-block', verticalAlign: 'top', float: 'right', marginRight: '2%'}}></div>
				</div>

                    <div style={{marginLeft: 20, marginRight: 20}}>
                         <a href="/register" style={{height: 34, textDecoration: 'none', paddingTop: 6, fontSize: 14, textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', background: 'linear-gradient(to bottom, #337ab7 0%, #265a88 100%)', color: '#FFFFFF', textAlign: 'center', border: '1px solid #245580', display: 'block', width: '100%'}}>Join Us</a>
                    </div>

               </IonContent>
          </IonPage>
  );
};

export default RegisterNotice;
