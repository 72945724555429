import React, { useState } from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { Geolocation, Geoposition } from '@ionic-native/geolocation';
import { IonContent, IonToast, IonButton, IonLoading, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonFooter } from '@ionic/react';
import { ApiUrl } from '../components/Global';
import './SearchResult.css';

interface SearchResultPageProps extends RouteComponentProps<{
  term: string;
  location: string;
}> {}

const GeolocationButton: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState({showError: false, message: ''});
    const [position, setPosition] = useState<Geoposition>();

    const getLocation = async () => {
        setLoading(true);

        try {
            const position = await Geolocation.getCurrentPosition();
            setPosition(position);
            setLoading(false);
            setError({ showError: false, message: '' });
        } catch (e) {
            setError({ showError: true, message: e.message });
            setLoading(false);
        }
    }

    return (
        <>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'Getting Location...'}
            />
            <IonToast
                isOpen={error.showError}
                onDidDismiss={() => setError({ message: "", showError: false })}
                message={error.message}
                duration={3000}
            />
            <IonButton color="primary" onClick={getLocation}>{position ? `${position.coords.latitude} ${position.coords.longitude}` : "Get Location"}</IonButton>
        </>
    );
};

const SearchResult: React.FC<SearchResultPageProps> = ({match}) => {

     const searchTerm = match.params.term;
     const searchLocation = match.params.location;

     const [error, setError] = React.useState({showError: false, message: ''});
     const [position, setPosition] = React.useState<Geoposition>();


     const [profiles, setProfiles] = React.useState([]);
     const [termData, setTermData] = React.useState({name: ''});

     const fetchTermData = () => {

          if(position)
          {
               console.log('here');
               console.log(position);
          }

          return axios(
          {
               url: ApiUrl+'return-term-data/'+searchTerm,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     const fetchSearchResults = async () => {

          try
          {

               const position = await Geolocation.getCurrentPosition();

               // setPosition(position);

               // console.log(position);


               var latitude = JSON.stringify(position.coords.latitude);
               var longitude = JSON.stringify(position.coords.longitude);


               let bodyFormData = new FormData();
               bodyFormData.append('term', searchTerm);
               bodyFormData.append('location', searchLocation);
               bodyFormData.append('latitude', latitude);
               bodyFormData.append('longitude', longitude);

               return axios(
               {
                    url: ApiUrl+'return-search-results',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response.data);
                    return response.data;
               })
          }
          catch(e)
          {
               let bodyFormData = new FormData();
               bodyFormData.append('term', searchTerm);
               bodyFormData.append('location', searchLocation);

               return axios(
               {
                    url: ApiUrl+'return-search-results',
                    method: 'post',
                    data: bodyFormData
               }).then(response => {
                    console.log(response.data);
                    return response.data;
               })
          }
     };

     React.useEffect(() => {
          fetchSearchResults().then(data => setProfiles(data));
          fetchTermData().then(data => setTermData(data));
     }, []);

     function RenderFeaturedUploads(userId:string, uploads:any) {
          let imageOutput = [];
          let imageId = 0;

          for (let featuredUpload of uploads)
          {
               console.log(featuredUpload);
               imageOutput.push(
                    <a href={"/profile/"+userId+"/uploads/"+imageId}><div style={{display: 'inline-block', width: 51, height: 51, border: '1px solid #FFF', backgroundPosition: 'center center', backgroundImage: "url('"+featuredUpload["path"]+"')", backgroundSize: 'cover'}}></div></a>
               )

               imageId++;
          }

          return imageOutput;
     }

     return (
          <IonPage>
               <IonContent>

               <div style={{marginTop: 80}}>

                    <div style={{marginTop: 20, marginBottom: 10, paddingLeft: 15}}>
                         <h3 style={{fontSize: 14, fontWeight: 'bold', fontStyle: 'italic', color: '#3470af', margin:0}}>{termData.name}</h3>
                    </div>

                    {
                         profiles.map(userInfo => {

                              return (
                                   <div key={userInfo["id"]} style={{marginBottom: 30}}>
                                        <div style={{padding: 0, marginBottom: 0, height: 190, position: 'relative', width: '100%'}}>
                                             <a href={"/profile/"+userInfo["users_id"]} style={{padding: 0, textDecoration: 'none'}}>
                                                  <div style={{color: '#ffffff', fontSize: 15, marginTop: 5, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 6, backgroundColor: userInfo["micro_color"], borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF', textAlign: 'center'}}><div style={{color: '#ffffff', textAlign: 'center', fontWeight: 'bold'}}>{userInfo['name']}</div></div>
                                                  <div style={{margin:0, padding: 0, overflow: 'visible', borderBottomWidth: 3, borderBottomStyle: 'solid', borderBottomColor: '#FFFFFF'}}>
                                                       <div style={{float: 'left'}}>
                                                            <img src={userInfo['profile_picture']} style={{width:110, marginRight: 10, marginBottom: -6}} />
                                                       </div>
                                                       <div style={{backgroundColor: '#859fc4', height: 110, marginLeft: 110, position: 'relative'}}>
                                                            <div style={{ fontSize: 13, color: '#FFFFFF', paddingLeft: 10, paddingTop: 5}}>
                                                                 <div style={{marginBottom: 2}}>{userInfo['city_name']}</div>
                                                                 <div style={{marginBottom: 2}}>{userInfo['country_name']}</div>
                                                            </div>
                                                            <div style={{paddingRight: 10, paddingTop: 8}}>
                                                                 <div style={{color: '#FFFFFF', fontSize: 13, marginRight: 10, marginBottom: 4, fontStyle: 'italic', maxHeight: 32, overflow: 'hidden'}}>{userInfo['description']}</div>
                                                                 <div style={{fontSize: 13, color: '#2f587b'}}>[more]</div>
                                                            </div>
                                                       </div>

                                                  </div>
                                             </a>
                                             <div style={{backgroundColor: '#7386ae', color: '#FFFFFF', padding: '5px', fontSize: '15px'}}>
                                                  {(userInfo['distance'] != "") ?
                                                       <div style={{float: 'right', paddingRight: 4, fontSize: 12, fontStyle: 'italic'}}>Distance: {userInfo['distance']} KM</div>
                                                       :
                                                       <div style={{float: 'right', paddingRight: 4, fontSize: 12, fontStyle: 'italic'}}></div>
                                                  }
                                                  <div>{userInfo['label']}</div>
                                                  <div style={{color: '#FFFFFF'}}>{userInfo['keyword']['name']}</div>
                                             </div>
                                        </div>
                                   </div>
                              );
                         })
                    }
               </div>

               </IonContent>
          </IonPage>
     );
};

export default SearchResult;
