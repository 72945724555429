import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { IonContent, IonIcon, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonSlides, IonSlide, IonActionSheet, IonButton } from '@ionic/react';
import { desktop, mail, call } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import './MyProfileUploads.css';
import { ApiUrl } from '../components/Global';

interface UserDetailPageProps extends RouteComponentProps<{
  imageId: string;
}> {}

const MyProfileUploads: React.FC<UserDetailPageProps> = ({match}) => {

     const userId = localStorage.getItem('publicUserId');

     const [userUploads, setUserUploads] = React.useState([]);
     const [showActionSheet, setShowActionSheet] = React.useState(false);

     const [downloadUrl, setDownloadUrl] = React.useState("");

     const slideOpts = {
          initialSlide: (match.params.imageId !== undefined) ? match.params.imageId : 0,
          speed: 400,
          navigation: {
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
          }
     };

     const fetchUploads = () => {

          return axios(
          {
               url: ApiUrl+'return-user-uploads/'+userId,
               method: 'get'
          }).then(response => {
               console.log(response.data);
               return response.data;
          })
     };

     function download()
     {
          axios.get(downloadUrl,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => console.log(error));


     }

     function showUploadActionSheet(filePath:string)
     {
          setDownloadUrl(filePath);
          setShowActionSheet(true)
     }

     React.useEffect(() => {

          fetchUploads().then(data => setUserUploads(data));
     }, []);

     function RenderUploads() {
          let imageOutput = []

          for (let uploadItem of userUploads[0]["uploads"])
          {
               console.log(uploadItem);
               if(uploadItem["upload_type"] == "video")
               {
                    imageOutput.push(
                         <IonSlide key={uploadItem["id"]} style={{position: 'relative'}}>
                              <IonButton color="dark" onClick={() => showUploadActionSheet(uploadItem["path"])} expand="block" style={{backgroundColor: '#7386ae', width: 50, height: 50, position: 'absolute', textAlign: 'center', top: 0, right: 4, zIndex: 100, borderRadius: 100, opacity: 0.7, border: '2px solid #FFFFFF', padding: 1, overflow: 'hidden'}}>
                                   <img src="./assets/icon/more-menu.png" style={{maxWidth: '200%'}} />
                              </IonButton>
                              <div>
                                   <video poster={uploadItem["thumbnail"]} controls style={{maxWidth: '100%', maxHeight: '42vh', marginTop: 5, flex: 1}}>
                                        <source src={uploadItem["path"]} type="video/mp4"></source>
                                   </video>
                                   <div style={{marginBottom: 10, backgroundColor: '#eeeeee', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, textAlign: 'left', fontSize: 14}}>{uploadItem["title"]}</div>
                                   <div style={{marginBottom: 10, paddingLeft: 15, textAlign: 'left', fontSize: 12}}>{uploadItem["description"]}</div>
                              </div>
                         </IonSlide>
                    )
               }
               else if(uploadItem["upload_type"] == "image")
               {
                    imageOutput.push(
                         <IonSlide key={uploadItem["id"]} style={{position: 'relative'}}>
                              <IonButton color="dark" onClick={() => showUploadActionSheet(uploadItem["path"])} expand="block" style={{backgroundColor: '#7386ae', width: 50, height: 50, position: 'absolute', textAlign: 'center', top: 0, right: 4, zIndex: 100, borderRadius: 100, opacity: 0.7, border: '2px solid #FFFFFF', padding: 1, overflow: 'hidden'}}>
                                   <img src="./assets/icon/more-menu.png" style={{maxWidth: '200%'}} />
                              </IonButton>
                              <div>
                                   <img src={uploadItem["path"]} />
                                   <div style={{marginBottom: 10, backgroundColor: '#eeeeee', paddingTop: 8, paddingBottom: 8, paddingLeft: 15, textAlign: 'left', fontSize: 14}}>{uploadItem["title"]}</div>
                                   <div style={{marginBottom: 10, paddingLeft: 15, textAlign: 'left', fontSize: 12}}>{uploadItem["description"]}</div>
                              </div>
                         </IonSlide>
                    )
               }
          }

          return imageOutput;
     }

     return (
          <IonPage>
               <IonHeader>
                    <IonToolbar>
                         <div style={{marginTop:80}}>
                              {
                                   userUploads.map(userInfo => {

                                        if(userInfo['is_profile'] !== 0)
                                        {
                                             return (
                                                  <div key={userInfo["id"]}>
                                                       <div style={{paddingTop: 20, paddingBottom: 3, marginBottom: 20, marginLeft: -5, marginRight: -5, backgroundColor: '#FFFFFF'}}>
                                                            <div style={{backgroundColor: userInfo["micro_color"], height: 50, paddingLeft: 8, position: 'relative'}}>
                                                                 <div style={{display: 'inline-block'}}>
                                                                      <img src={userInfo['profile_picture']} style={{height: 80, marginTop: -15, marginBottom: -15}} />
                                                                 </div>
                                                                 <div style={{display: 'inline-block', position: 'absolute', left: 88, right: 0, top: 0, bottom: 0}}>
                                                                      <div style={{display: 'inline-block', width: '76%', padding: 5, position: 'relative', height: 50}}>
                                                                           <h2 style={{fontSize: 16, fontWeight: 'normal', color: '#ffffff', margin: 0}}>{userInfo['name']}</h2>
                                                                           <div className="borderSlantedEdge"></div>
                                                                      </div>
                                                                      <a href={"/my-profile"}  className="slantedEdge" style={{display: 'inline-block', textDecoration: 'none', width: '22%', fontWeight: 'bold', height: '100%', background: '#7386ae', float: 'right', textAlign: 'center', color: '#f3df9c', textTransform: 'uppercase', fontSize: 12, paddingTop: 3, position: 'relative'}}>
                                                                           Back<br />To<br />Profile
                                                                      </a>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             )
                                        }
                                   })
                              }
                         </div>
                    </IonToolbar>
               </IonHeader>
               <IonContent style={{position: 'relative'}}>
                    {
                         userUploads.map(userInfo => {

                              if(userInfo['is_profile'] !== 0)
                              {
                                   return (
                                        <div>
                                             <IonSlides options={slideOpts}>
                                                  {RenderUploads()}
                                             </IonSlides>
                                             <div className="swiper-button-prev"></div>
                                             <div className="swiper-button-next"></div>
                                        </div>
                                   )
                              }
                         })
                    }
                    <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[{
          text: 'Like',
          handler: () => {
            console.log('Delete clicked');
          }
        }, {
          text: 'Share',
          handler: () => {
            console.log('Share clicked');
          }
        }, {
          text: 'Comments',
          handler: () => {
            console.log('Play clicked');
          }
        }, {
          text: 'Download',
          handler: () => {
            download()
          }
        }, {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]}
      >
      </IonActionSheet>
               </IonContent>
          </IonPage>
  );
};

export default MyProfileUploads;
