import React from 'react';
import './Advert.css';


const Advert: React.FC = () => {
     return (
          <div style={{textAlign:'center'}}>
               <div style={{maxHeight: 400, width:250, overflow: 'hidden', display: 'inline-block'}}>
                    <img src={"https://dev.penewo.com/images/default-adverts/advert_default_"+Math.floor((Math.random() * 21) + 1)+".jpg"} style={{maxWidth: '100%', marginBottom: 25}} />
               </div>
          </div>
     );
};

export default Advert;
