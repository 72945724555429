import React from 'react';
import { Redirect, Route, RouteComponentProps, match } from 'react-router-dom';
import axios from 'axios';
import { IonContent, IonHeader, IonToolbar, IonInput, IonLabel, IonList, IonItem, IonPage, IonGrid, IonRow, IonCol, IonAvatar, IonTitle, IonButton } from '@ionic/react';
import './Chat.css';
import { ApiUrl } from '../components/Global';

interface ChatPageProps extends RouteComponentProps<{
  id: string;
}> {}

const Chat: React.FC<ChatPageProps> = ({match}) => {
     const [ submitForm, setSubmitForm ] = React.useState(false);
     const [replyValue, setReplyValue] = React.useState('');

     const chatId = match.params.id;

     const [ chatItems, setChatItems] = React.useState([]);
     const [chatParticipant, setChatParticipant] = React.useState({name: '', profile_picture: ''});

     const userId = localStorage.getItem('publicUserId');
     const privateUserId:any = localStorage.getItem('userId');

     const fetchChatItems = () => {

          let bodyFormData = new FormData();
          bodyFormData.append('privateUserId', privateUserId);
          bodyFormData.append('chatId', chatId);

          return axios(
          {
               url: ApiUrl+'return-chat-items',
               method: 'post',
               data: bodyFormData,
               headers: {'Content-Type': 'multipart/form-data' }
          }).then(response => {
               console.log(response);

               return response.data;

          });
     };

     const fetchChatParticipant = () => {

          let bodyFormData = new FormData();
          bodyFormData.append('privateUserId', privateUserId);
          bodyFormData.append('chatId', chatId);

          return axios(
          {
               url: ApiUrl+'return-chat-participants',
               method: 'post',
               data: bodyFormData,
               headers: {'Content-Type': 'multipart/form-data' }
          }).then(response => {
               console.log(response);

               return response.data;

          });
     };

     React.useEffect(() => {

          fetchChatItems().then(data => {
               setChatItems(data);
          });

          fetchChatParticipant().then(data => {
               setChatParticipant(data);
          });

     }, []);

     let currentDate:string = "";
     let dateChange:boolean = false;

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, paddingTop: 4}}>
                         <IonHeader style={{position: 'fixed'}}>
                              <IonToolbar style={{background: '#EEE', borderBottom: '1px solid #AAA'}}>
                                   <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                                        <img src={chatParticipant.profile_picture} style={{width: 50, height: 50, marginBottom: -4, border: '1px solid #EEE', float: 'left', marginRight: 4}} />
                                   </div>
                                   <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                                        <div style={{fontSize: 12, fontWeight: 'bold', color: '#485f89'}}>{chatParticipant.name}</div>
                                   </div>
                              </IonToolbar>
                         </IonHeader>

                         <div style={{marginTop: 6, background: '#FFF', padding: 15}}>

                              <div style={{paddingTop: 25, paddingBottom: 120}}>

                                   {
                                        chatItems.map(chatItem => {
                                             if(currentDate === "" || currentDate !== chatItem["date"])
                                             {
                                                  currentDate = chatItem["date"];
                                                  dateChange = true;
                                             }
                                             else
                                             {
                                                  dateChange = false;
                                             }

                                             if(chatItem["users_id"] == userId)
                                             {
                                                  return (
                                                       <div key={chatItem["id"]}>
                                                            {(dateChange) ? <div style={{fontSize: 11, marginTop: 28, marginBottom: 2, textAlign: 'center'}}>{chatItem["date"]}</div> : ""}
                                                            <div style={{background: '#a1b2cd', marginBottom: 9, minHeight: 20, paddingTop: 3, paddingBottom: 2}}>
                                                                 <div style={{float: 'right', paddingTop: 2, paddingRight: 8, color: '#FFF', background: '#a1b2cd', fontSize: 11, marginLeft: -1, textAlign: 'right'}}>{chatItem["time"]}</div>
                                                                 <div style={{paddingLeft: 18, paddingRight: 8, color: '#FFF', background: '#a1b2cd', fontSize: 12}}>
                                                                      {chatItem["message"]}
                                                                 </div>

                                                            </div>
                                                       </div>
                                                  )
                                             }
                                             else
                                             {
                                                  return (
                                                       <div key={chatItem["id"]}>
                                                            {(dateChange) ? <div style={{fontSize: 11, marginTop: 28, marginBottom: 2, textAlign: 'center'}}>{chatItem["date"]}</div> : ""}
                                                            <div style={{background: '#EEE', marginBottom: 9, minHeight: 20, paddingTop: 2, paddingBottom: 2}}>
                                                                 <div style={{float: 'right', paddingTop: 2, paddingRight: 8, color: '#777', background: '#EEE', fontSize: 11, marginLeft: -1, textAlign: 'right'}}>{chatItem["time"]}</div>
                                                                 <div style={{paddingLeft: 8, paddingRight: 8, background: '#EEE', fontSize: 12}}>
                                                                      {chatItem["message"]}
                                                                 </div>

                                                            </div>
                                                       </div>
                                                  )
                                             }
                                        })
                                   }

                                   <div style={{position: 'fixed', left: 0, right: 0, bottom: 0, background: '#EEE', paddingBottom: 6, paddingTop: 4, borderTop: '1px solid #AAA'}}>
                              		<div style={{paddingLeft: 6, paddingRight: 6}}>
                              			<div style={{marginBottom: 8, paddingTop: 3}}><textarea style={{height: 80, width: '100%', fontSize: 12, paddingLeft: 4, border: '1px solid #EEE;'}} placeholder="Leave your Reply"></textarea></div>
                              			<div><button style={{background: '#003e63', color: '#FFF', width: '100%', textAlign: 'center', fontSize: 15, paddingTop: 6, paddingBottom: 6}}>post reply</button></div>

                              		</div>
                                   </div>

                              </div>

                         </div>
                    </div>
               </IonContent>
          </IonPage>
     );
};

export default Chat;
