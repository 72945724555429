import React from 'react';
import { SyntheticEvent, MouseEvent } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
     IonApp,
     IonIcon,
     IonLabel,
     IonRouterOutlet,
     IonTabBar,
     IonTabButton,
     IonTabs,
     IonHeader,
     IonPage,
     IonTitle,
     IonToolbar,
     IonGrid,
     IonRow,
     IonCol,
     IonImg,
     IonThumbnail,
     IonItem,
     IonText,
     IonChip,
     IonMenu,
     IonContent,
     IonList,
     IonMenuButton,
} from '@ionic/react';
import { menuController } from '@ionic/core';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, caretBackOutline, triangle, home, person, archive, chatbubbles, apps, card, pin, star, pricetag, business, book } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import Home from './pages/Home';
import SearchKeyword from './pages/SearchKeyword';
import SearchLocation from './pages/SearchLocation';
import SearchName from './pages/SearchName';
import SearchResult from './pages/SearchResult';
import Profile from './pages/Profile';
import ProfileUploads from './pages/ProfileUploads';
import RegisterNotice from './pages/RegisterNotice';
import Login from './pages/Login';
import Register from './pages/Register';
import MyProfile from './pages/MyProfile';
import MyProfileShare from './pages/MyProfileShare';
import MyProfileUploads from './pages/MyProfileUploads';
import EditOverview from './pages/EditOverview';
import EditAboutMe from './pages/EditAboutMe';
import EditContact from './pages/EditContact';
import EditAddress from './pages/EditAddress';
import EditMap from './pages/EditMap';
import EditNamecard from './pages/EditNamecard';
import EditKeywords from './pages/EditKeywords';
import EditPassword from './pages/EditPassword';
import EditUrl from './pages/EditUrl';
import EditFeaturedUploads from './pages/EditFeaturedUploads';
import EditProfilePicture from './pages/EditProfilePicture';
import EditIncognito from './pages/EditIncognito';
import AddUpload from './pages/AddUpload';
import Share from './pages/Share';
import NewsFeed from './pages/NewsFeed';
import NewsFeedSample from './pages/NewsFeedSample';
import Chats from './pages/Chats';
import Chat from './pages/Chat';
import Pressroom from './pages/Pressroom';
import AboutUs from './pages/AboutUs';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';


const App: React.FC = () => {

     const [ sideMenuClass, setSideMenuClass ] = React.useState('sidemenu');
     const [ sideMenuBackgroundClass, setSideMenuBackgroundClass ] = React.useState('sidemenu-background');

     let loggedIn = false;
     if(localStorage.getItem('loggedIn') != null)
     {
          loggedIn = true;
     }

     function toogleSideMenu(e: SyntheticEvent )
     {
          e.preventDefault();
          sideMenuClass == "sidemenu-shown sidemenu-slide-in" ? setSideMenuClass('sidemenu sidemenu-slide-out') : setSideMenuClass('sidemenu-shown sidemenu-slide-in');
          sideMenuBackgroundClass == "sidemenu-background-shown" ? setSideMenuBackgroundClass('sidemenu-background') : setSideMenuBackgroundClass('sidemenu-background-shown');
     }

     function logOut()
     {
          localStorage.removeItem('loggedIn');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          localStorage.removeItem('userUrl');
          localStorage.removeItem('userEmail');
          localStorage.removeItem('userAvatar');
          document.location.href = "/home";
     }

     function renderSideMenu()
     {
          let loggedIn = false;

          if(localStorage.getItem('loggedIn') != null)
          {
               loggedIn = true;
          }

          if(!loggedIn)
          {
               return (
                    <div>
                         <div className={sideMenuBackgroundClass} onClick={e => toogleSideMenu(e)}></div>

                         <div className={sideMenuClass}>
                              <ul style={{padding: 0, paddingTop: 6, margin: 0}}>
                                   <li style={{listStyleType: 'none'}}>
                                        <div style={{borderBottom: '1px solid #DDD', background: '#f3f3f3', marginTop:1, marginBottom:8}}>
                                             <a href="/login" style={{color: '#337ab7', textDecoration: 'none', fontSize: 14, width: '100%', padding: 5, display: 'block', paddingLeft:15}}> Login</a>
                                        </div>
                                   </li>
                                   <li style={{listStyleType: 'none'}}>
                                        <div style={{borderBottom: '1px solid #DDD', background: '#f3f3f3', marginTop:1, marginBottom:8}}>
                                             <a href="/register" style={{color: '#337ab7', textDecoration: 'none', fontSize: 14, width: '100%', padding: 5, display: 'block', paddingLeft:15}}> Register</a>
                                        </div>
                                   </li>
                              </ul>
                         </div>
                    </div>
               );
          }
          else
          {
               let userName = null;
               let profilePicture = "";

               if(localStorage.getItem('userName') != null)
               {
                    userName = localStorage.getItem('userName');
               }

               if(localStorage.getItem('userAvatar') != null)
               {
                    profilePicture = "https://dev.penewo.com/users/"+localStorage.getItem('userAvatar');
               }

               return (
                    <div>
                         <div className={sideMenuBackgroundClass} onClick={e => toogleSideMenu(e)}></div>

                         <div className={sideMenuClass}>
                              <ul style={{padding: 0, paddingTop: 6, margin: 0}}>
                                   <li style={{listStyleType: 'none'}}>
                                        <div style={{minHeight: 60, background: '#7a7e86', marginBottom:8}}>
                                             <div style={{marginRight: 10, height: 60, display: 'inline-block', verticalAlign: 'top', position: 'absolute'}}>
                                                  <img src={profilePicture} style={{width: 60, height: 60}} />
                                             </div>
                                             <div style={{display: 'inline-block', verticalAlign: 'top', marginLeft: 68}}>
                                                  <div><div style={{fontSize:12, fontWeight: 'bold', color:'#FFF', marginTop: 5}}>{userName}</div></div>
                                             </div>
                                        </div>
                                   </li>
                                   <li style={{listStyleType: 'none'}}>
                                        <div style={{borderBottom: '1px solid #DDD', background: '#f3f3f3', marginTop:1, marginBottom:8}}>
                                             <div onClick={() => logOut()} style={{color: '#337ab7', textDecoration: 'none', fontSize: 14, width: '100%', padding: 5, display: 'block', paddingLeft:15}}> Logout</div>
                                        </div>
                                   </li>
                              </ul>
                         </div>
                    </div>
               );
          }
     }

     function renderNavBar()
     {
          let loggedIn = false;
          if(localStorage.getItem('loggedIn') != null)
          {
               loggedIn = true;
          }

          //console.log(loggedIn);

          if(!loggedIn)
          {
               return (
                    <IonTabBar slot="bottom">
                         <IonTabButton tab="home" href="/home">
                              <IonIcon icon={home} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>Home</IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="register-notice" href="/register-notice">
                              <IonIcon icon={person} style={{fontSize: 20, marginTop: 4, position: 'relative', zIndex: 1, color: '#FF9800'}} />
                              <IonLabel style={{fontSize: 12, color: '#FF9800'}}><span style={{display: 'block', position: 'relative', zIndex: 100, marginTop: -2, marginBottom: -3}}>Join Us</span><span>Login</span></IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="news-feed-sample" href="/news-feed-sample">
                              <IonIcon icon={book} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>News Page</IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="about-us" href="/about-us">
                              <IonIcon icon={business} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>About Us</IonLabel>
                         </IonTabButton>
                         <IonTabButton onClick={e => toogleSideMenu(e)} style={{backgroundColor: '#06497e'}}>
                              <IonIcon icon={apps} style={{fontSize: 20, marginBottom: 3, color: '#FFFFFF'}} />
                              <IonLabel style={{fontSize: 12, color: '#FFFFFF'}}>More</IonLabel>
                         </IonTabButton>
                    </IonTabBar>
               )
          }
          else
          {
               return (
                    <IonTabBar slot="bottom">
                         <IonTabButton tab="home" href="/home">
                              <IonIcon icon={home} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>Home</IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="my-profile" href="/my-profile">
                              <IonIcon icon={person} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>Profile</IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="news-feed" href="/news-feed">
                              <IonIcon icon={archive} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>News Page</IonLabel>
                         </IonTabButton>
                         <IonTabButton tab="chats" href="/chats">
                              <IonIcon icon={chatbubbles} style={{fontSize: 20, marginBottom: 3, color: '#337ab7'}} />
                              <IonLabel style={{fontSize: 12, color: '#337ab7'}}>Chat</IonLabel>
                         </IonTabButton>
                         <IonTabButton onClick={e => toogleSideMenu(e)} style={{backgroundColor: '#06497e'}}>
                              <IonIcon icon={apps} style={{fontSize: 20, marginBottom: 3, color: '#FFFFFF'}} />
                              <IonLabel style={{fontSize: 12, color: '#FFFFFF'}}>More</IonLabel>
                         </IonTabButton>
                    </IonTabBar>
               )
          }
     }


     return (
     <IonApp style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
          <IonHeader style={{borderBottom: '1px solid #DDD'}}>
               <IonToolbar>
                    <IonGrid>
                         <IonRow>
                              <IonCol>
                                        { (!loggedIn) ?
                                             <IonGrid>
                                                  <IonRow style={{marginLeft: -20, marginRight: -20}}>
                                                       <IonCol style={{width: "100vw", marginTop: -18, padding: 0}}>
                                                            <div style={{marginTop: "-2px"}}>
                                                                 <div style={{background: "#e4ebf5", color: "#3470af", fontWeight: "bold", textAlign: "center", fontStyle: "italic", borderLeft: "1px solid #FFF", zIndex: 10, float: "right", padding: "4px 10px 0", marginBottom: "3px"}}><a href="/press-room" style={{color: "#3470af", display: "inline-block", textDecoration: 'none', fontSize: "13px", lineHeight: "12px"}}>press release<br />contact us</a></div>
                                                                 <div>
                                                                      <img src="https://dev.penewo.com/images/penewo-logo-green-small.png?t=2" style={{width: 110, paddingTop: "4px", paddingLeft: "12px"}} />
                                                                      <br />
                                                                      <div style={{fontSize: "12px", color: "#4373aa", fontStyle: "italic", verticalAlign: "bottom", marginTop: "-9px", fontWeight: "bolder", lineHeight: "14px", paddingLeft: "12px"}}>beta - report a bug</div>
                                                                 </div>
                                                            </div>
                                                       </IonCol>
                                                  </IonRow>
                                                  <IonRow style={{marginLeft: -30, marginBottom: -30, marginRight:-30, paddingBottom: 5}}>
                                                       <IonCol size="3" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "left", paddingLeft: "18px"}}>
                                                            <div>
                                                                 <a href="/search/location" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search by</div>
                                                                      <div>location</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                       <IonCol size="6" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "center"}}>
                                                            <div>
                                                                 <a href="/search/keyword" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search someone</div>
                                                                      <div>by your interests</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                       <IonCol size="3" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "left", paddingLeft: "18px"}}>
                                                            <div>
                                                                 <a href="/search/name" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search by</div>
                                                                      <div>name</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                             :
                                             <IonGrid>
                                                  <IonRow style={{marginLeft: -20, marginRight: -20}}>
                                                  <IonCol style={{width: "100vw", marginTop: -18, padding: 0}}>
                                                       <div style={{marginTop: "-2px"}}>
                                                            <div style={{background: "#e4ebf5", color: "#3470af", fontWeight: "bold", textAlign: "center", fontStyle: "italic", borderLeft: "1px solid #FFF", zIndex: 10, float: "right", padding: "4px 10px 0", marginBottom: "3px"}}><a href="/my-profile/share" style={{color: "#3470af", display: "inline-block", textDecoration: 'none', lineHeight: "26px", fontSize: "13px"}}>name card</a></div>
                                                            <div style={{background: "#e4ebf5", color: "#3470af", fontWeight: "bold", textAlign: "center", fontStyle: "italic", borderLeft: "1px solid #FFF", zIndex: 10, float: "right", padding: "4px 10px 0", marginBottom: "3px"}}><a href="/press-room" style={{color: "#3470af", display: "inline-block", textDecoration: 'none', fontSize: "13px", lineHeight: "12px"}}>press release<br />contact us</a></div>
                                                            <div>
                                                                 <img src="https://dev.penewo.com/images/penewo-logo-green-small.png?t=2" style={{width: 110, paddingTop: "4px", paddingLeft: "12px"}} />
                                                                 <br />
                                                                 <div style={{fontSize: "12px", color: "#4373aa", fontStyle: "italic", verticalAlign: "bottom", marginTop: "-9px", fontWeight: "bolder", lineHeight: "14px", paddingLeft: "12px"}}>beta - report a bug</div>
                                                            </div>
                                                       </div>
                                                  </IonCol>
                                                  </IonRow>
                                                  <IonRow style={{marginLeft: -30, marginBottom: -30, marginRight:-30, paddingBottom: 5}}>
                                                       <IonCol size="3" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "left", paddingLeft: "18px"}}>
                                                            <div>
                                                                 <a href="/search/location" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search by</div>
                                                                      <div>location</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                       <IonCol size="6" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "center"}}>
                                                            <div>
                                                                 <a href="/search/keyword" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search someone</div>
                                                                      <div>by your interests</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                       <IonCol size="3" style={{margin: "-3 10px 0", border: "2px solid #FFF", borderTop: 0, padding: "4px 0 10px", background: "#3470af", color:"#FFF", textAlign: "left", paddingLeft: "18px"}}>
                                                            <div>
                                                                 <a href="/search/name" style={{color: '#FFF', textDecoration: 'none', fontSize: "13px", lineHeight: "15px", fontWeight: "bold"}}>
                                                                      <div>search by</div>
                                                                      <div>name</div>
                                                                 </a>
                                                            </div>
                                                       </IonCol>
                                                  </IonRow>
                                             </IonGrid>
                                        }
                              </IonCol>
                         </IonRow>
                    </IonGrid>
               </IonToolbar>
          </IonHeader>

          {renderSideMenu()}

          <IonReactRouter>
               <IonTabs>
                    <IonRouterOutlet>
                         <Route path="/home" component={Home} exact={true} />
                         <Route path="/search/keyword" component={SearchKeyword} exact={true} />
                         <Route path="/search/location" component={SearchLocation} exact={true} />
                         <Route path="/search/name" component={SearchName} exact={true} />
                         <Route path="/search/result/:term/:location" component={SearchResult} exact={true} />
                         <Route path="/profile/:id" component={Profile} exact={true} />
                         <Route path="/profile/:id/uploads" component={ProfileUploads} exact={true} />
                         <Route path="/profile/:id/uploads/:imageId" component={ProfileUploads} exact={true} />
                         <Route path="/profile/:id/share" component={Share} exact={true} />
                         <Route path="/register-notice" component={RegisterNotice} exact={true} />
                         <Route path="/register" component={Register} exact={true} />
                         <Route path="/login" component={Login} exact={true} />
                         <Route path="/my-profile" component={MyProfile} exact={true} />
                         <Route path="/my-profile/share" component={MyProfileShare} exact={true} />
                         <Route path="/my-profile/uploads" component={MyProfileUploads} exact={true} />
                         <Route path="/my-profile/uploads/:imageId" component={MyProfileUploads} exact={true} />
                         <Route path="/my-profile/edit" component={EditOverview} exact={true} />
                         <Route path="/my-profile/edit/aboutMe" component={EditAboutMe} exact={true} />
                         <Route path="/my-profile/edit/contact" component={EditContact} exact={true} />
                         <Route path="/my-profile/edit/address" component={EditAddress} exact={true} />
                         <Route path="/my-profile/edit/map" component={EditMap} exact={true} />
                         <Route path="/my-profile/edit/micro" component={EditNamecard} exact={true} />
                         <Route path="/my-profile/edit/keywords" component={EditKeywords} exact={true} />
                         <Route path="/my-profile/edit/password" component={EditPassword} exact={true} />
                         <Route path="/my-profile/edit/url" component={EditUrl} exact={true} />
                         <Route path="/my-profile/edit/featured" component={EditFeaturedUploads} exact={true} />
                         <Route path="/my-profile/edit/profilePicture" component={EditProfilePicture} exact={true} />
                         <Route path="/my-profile/edit/incognito" component={EditIncognito} exact={true} />
                         <Route path="/my-profile/create-upload" component={AddUpload} exact={true} />
                         <Route path="/news-feed" component={NewsFeed} />
                         <Route path="/news-feed-sample" component={NewsFeedSample} />
                         <Route path="/chats" component={Chats} exact={true} />
                         <Route path="/chats/:id" component={Chat} exact={true} />
                         <Route path="/press-room" component={Pressroom} exact={true} />
                         <Route path="/about-us" component={AboutUs} exact={true} />
                         <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
                    </IonRouterOutlet>
                    {renderNavBar()}
               </IonTabs>
          </IonReactRouter>
     </IonApp>
)};

export default App;
