import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonLabel, IonTextarea, IonInput, IonGrid, IonCol, IonRow, IonToolbar, IonModal, IonButtons, IonButton, IonSlides, IonSlide, IonList, IonItem } from '@ionic/react';
import './Pressroom.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/react/css/ionic-swiper.css';


const Pressroom: React.FC = () => {

     const [showModalPressRelease, setShowModalPressRelease] = React.useState(false);
     const [showModalAboutUs, setShowModalAboutUs] = React.useState(false);
     const [showModalSummary, setShowModalSummary] = React.useState(false);
     const [showModalWebpageMovie, setShowModalWebpageMovie] = React.useState(false);
     const [showModalNewsfeedMovie, setShowModalNewsfeedMovie] = React.useState(false);
     const [showModalContactUs, setShowModalContactUs] = React.useState(false);

     const [submitForm, setSubmitForm] = React.useState(false);

     return (
          <IonPage>
               <IonContent>
                    <div style={{marginTop: 60, padding: 15}}>
                         <div style={{position: "relative"}}>
                              <div style={{textAlign: "right"}}>
                                   <img src="https://dev.penewo.com/images/press-room/logo.jpg" style={{ maxWidth: "60%", marginTop: "2vw"}} />
                              </div>

                              <div style={{ position: "fixed", top: "20vw", bottom: 0, left: 0, right: 0, background: "url('https://dev.penewo.com/images/press-room/background.jpg') center center", backgroundSize: "cover"}}>
                                   <div style={{ position:"absolute", width:"45%", top: 0, bottom:0, right:0}}>

                                        <div style={{background: "#008ea2", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalPressRelease(true)} style={{display: "block", padding: "15px 0 15px 12px", color: "#ffe89c"}}>Press<br />Release</div>
                                        </div>

                                        <div style={{background: "#4f4f51", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalAboutUs(true)} style={{display: "block", padding: "15px 0 15px 12px", color:"#ffe89c"}}>About Us<br />Penewo</div>
                                        </div>

                                        <div style={{background: "#4f4f51", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalSummary(true)} style={{display: "block", padding: "15px 0 15px 12px", color:"#ffe89c"}}>Penewo at<br />a glance</div>
                                        </div>

                                        <div style={{background: "#4f4f51", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalWebpageMovie(true)} style={{display: "block", padding: "15px 0 15px 12px", color:"#ffe89c"}}>Web Page<br />Movie</div>
                                        </div>

                                        <div style={{background: "#4f4f51", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalNewsfeedMovie(true)} style={{display: "block", padding: "15px 0 15px 12px", color:"#ffe89c"}}>News Feed<br />Movie</div>
                                        </div>

                                        <div style={{background: "#4f4f51", color: "#ffe89c", fontWeight: "bold", letterSpacing: "2px", fontSize: "18px", textTransform: "uppercase", marginBottom: "15px", lineHeight: "23px"}}>
                                             <div onClick={() => setShowModalContactUs(true)} style={{display: "block", padding: "15px 0 15px 12px", color:"#ffe89c"}}>Contact<br />Us</div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <IonModal isOpen={showModalPressRelease} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>Press Release</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalPressRelease(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{overflowY: "auto", background: "#FFF"}}>
                              <div style={{padding: "15px 15px 0", marginBottom: "45px"}}>
                                   <img src="https://dev.penewo.com/images/logo-current.jpg" style={{maxWidth: "200px", marginBottom: "10px"}} />

                                   <p>Slogan: We connect</p>
                                   <hr />
                                   <p style={{fontSize: "16px"}}>PENEWO (Peoples Network) - the new social network is focused on connecting people by interests.</p>
                                   <br />
                                   <p>To connect people Penewo offers several facilities:</p>
                                   <br />
                                   <p> - Free webpage like profiles that are linked and made visible via a search by interests.</p>
                                   <p> - A news feed that does not rely on surfing behavior but again on interests set by the user.</p>
                                   <p> - In addition, Penewo offers, for example, an electronic business card system, a free Penewo web address, an internal message system.</p>
                                   <p> - Penewo websites (profiles) also contain adjustable features like an incognito mode or a review setting.</p>
                                   <br />
                                   <p>Penewo is perfect for freelancers, companies of all sizes, scientists or research institutes who can be found by interests, products or services. Of course, it can also be used very well for private life (finding like-minded people for hobbies, fun, leisure time or dating).</p>
                                   <p>In the end, Penewo can be used by anyone who connects with others about any topic - a modern yellow page system - a connection and information network.</p>

                              </div>

                              <div style={{marginBottom: "35px", padding: "15px"}}>
                                   <p style={{fontSize: "16px", marginBottom: "15px"}}>PENEWO's main facilities are briefly outlined below:</p>
                                   <ul style={{paddingLeft: 15}}>
                                        <li style={{marginBottom: "15px"}}><b>SEARCH ENGINE</b> by interests, products or services.</li>
                                        <li>
                                             <b>FREE PENEWO WEBPAGE</b>
                                             <br />

                                             <p>
                                                  The scope of a Penewo webpage can be adjusted according to the needs of the user. The minimum requirement is a name and an interest. More information (maps, pictures, descriptions etc.) can be added. Even if Penewo has only recently opened its doors and doesn't have a lot of uploads, a free Penewo website has only advantages.
                                             </p>

                                             <ul style={{marginBottom: "15px"}}>
                                                  <li><b>It:</b></li>
                                                  <li>is connected (and therefore visible and findable) to the directory of interests</li>
                                                  <li>is connected also with the electronic name card system.</li>
                                                  <li>is connected with the news feed system.</li>
                                                  <li>offers the possibility of installing a review (for hotels etc.).</li>
                                                  <li>offers the possibility of setting an incognito mode</li>
                                                  <li>has a message system</li>
                                                  <li>has 2 different display modes for desktop viewers (compact or webpage mode)</li>
                                             </ul>

                                             <div style={{textAlign: "center", marginBottom: "35px", marginLeft: "-15px"}}>
                                                  <video poster="https://dev.penewo.com/press-room-webpage.jpg" controls style={{maxWidth: "100%", maxHeight: "42vh", marginTop: "5px", flex: 1}}>
                                                       <source src="https://dev.penewo.com/press-room-webpage.mp4" type="video/mp4" />
                                                  </video>
                                             </div>
                                        </li>
                                        <li style={{marginBottom: "15px"}}><b>ELECTRONIC NAME CARD SYSTEM</b> with a name card folder and the ability to upload your electronic name cards to other social media.</li>
                                        <li style={{marginBottom: "15px"}}><b>FREE PENEWO WEB ADDRESS</b> for every PENEWO webpage for your physical name card.</li>
                                        <li style={{marginBottom: "15px"}}>
                                             <b>NEWS FEED SYSTEM</b> by interests, friends and follows. You get the latest uploads by interests defined by you. Surfing behavior is not the basis for information.
                                             <div style={{marginTop: "15px", textAlign: "center", marginBottom: "45px", marginLeft: "-15px"}}>
                                                  <video poster="https://dev.penewo.com/press-room-newsfeed.jpg" controls style={{maxWidth: "100%", maxHeight: "42vh", marginTop: "5px", flex: 1}}>
                                                       <source src="https://dev.penewo.com/press-room-newsfeed.mp4" type="video/mp4" />
                                                  </video>
                                             </div>
                                        </li>
                                   </ul>
                              </div>

                              <div style={{marginBottom: "35px", padding: "15px"}}>
                                   <p>Overall PENEWO is an interesting and new alternative to connect and communicate as a business, a scientist or for example a freelancer or for private matters. Just have a look.</p>
                              </div>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalAboutUs} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>About Us - Penewo</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalAboutUs(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{overflowY: "auto", padding: "15px", background: "#fff"}}>
                              <div style={{marginBottom: "45px"}}>
                                   <img src="https://dev.penewo.com/images/logo-current.jpg" style={{maxWidth: "200px", marginBottom: "10px"}} />

                                   <p>Slogan: We connect</p>
                                   <hr />
                                   <p><b>Peter and Ruben</b></p>
                                   <p>Never in his life would Peter have thought that he would spend a large part of his life in Thailand.</p>
                                   <p>Peter, a Swiss national, grew up in Germany where he attended university, later worked for various companies but finally decided to move to Bangkok for some time. The "some time" extended to many years.</p>
                                   <p>It was one of those sunny Bangkok afternoons on Sukhumvit Soi 8 Road at an open restaurant when a friend introduced him to Ruben Wasserscheidt.</p>
                                   <p>Ruben, a full stack software developer, specializing in web and mobile apps, was born and raised in Germany, but relocated to Thailand during his teenage years.</p>
                                   <p>Peter was looking for a programmer. He had the idea of a company hub that included all external company connections like purchase, sales, employee search, etc. The company name was “Cosengi” (COmpany Search ENGIne). It was not the first attempt to approach this topic. So when he met Ruben, he made an appointment for the next week at a small cafe in a mall.</p>
                                   <p>The 2 guys discussed everything, time frame and workload etc. - came to an agreement and started to create COSENGI.</p>
                                   <p>After some time Peter had the task of creating and designing the basic function of the employee search and this was the beginning of PENEWO.</p>
                                   <br />
                                   <p><b>What is work anyway?</b></p>
                                   <p>You learn something and then get paid to use that know-how, but Peter figured that people had so much more knowledge to narrow it down just for work.</p>
                                   <p>How about we take ALL of everyone's knowledge and make it available to anyone who is looking for it? The bakery owner who knows about his tax program or the lawyer who acquired know how building a house? Anything is possible.</p>
                                   <p>It didn't stop there. We humans have desires, dreams and hopes. Why not make a program that takes care of all these things? Find other people for everything that interests you, whether private or business, science or freelance. People are looking for other people.</p>
                                   <p>Peter called Ruben and he remembers being surprised at how quickly Ruben got the idea. He needed seconds. "That's what we wanted to do."</p>
                                   <p>With neither being regular users of social media networks, they were unburdened by any prior knowledge and thus were able to look at the concept with fresh eyes and ideas.</p>
                                   <p>In the beginning, Penewo was very small AND had a different name. First they made the desktop version, then the Android version, then the IOS version and last but not least the app.</p>
                                   <p>The program itself initially consisted of search, micros and PENEWO webpages. Then a news feed was attached based on personal interests defined by the user. At the very end, it was decided to attach the so-called matrix, in which you can scroll mobile through uploads from different subscribers.</p>
                                   <p>It took both of them an endless time. In the end Ruben and Peter were pretty exhausted, but now the marketing had to start. Target groups! It was clear to both of them that the main target group were freelancers and they want to start there.</p>
                                   <p>Looking back, neither of them can fully understand why it has taken this long to develop PENEWO. In our current fast-moving world, prototypes are often developed and published within a few weeks to gauge user interest, but this wasn’t the path that these 2 Germans wanted to follow as they’ve set on their journey to make their vision become reality.</p>
                                   <p>As it is finished now and goes into the market both of them hope, that you like their creation.</p>

                              </div>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalSummary} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>Penewo At A Glance</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalSummary(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: "44px", left: 0, right: 0, bottom: 0, width: "100%", overflowY: "auto", background: "#828080"}}>
                              <div>
                                   <Swiper modules={[Pagination, Navigation]} pagination={true} navigation={true}>

                                        <SwiperSlide key={0}>
                                             <div>
                                                  <div style={{background: "linear-gradient(#231f20 0%, #231f20 85%, #565354 90%, #FFF 100%)", padding: "30px 45px"}}>
                                                       <div style={{textAlign: "center", marginBottom: "25px"}}>
                                                            <img src="https://dev.penewo.com/images/logo_thumb_dark.png" style={{maxWidth: "50px"}} />
                                                       </div>

                                                       <p style={{textTransform: "uppercase", color: "#f2e0be", textAlign: "center", fontSize: "17px", marginBottom: "30px"}}>
                                                            Improve your life
                                                       </p>

                                                       <p style={{color: "#FFF", fontSize: "16px", marginBottom: "20px", letterSpacing: "1px"}}>
                                                            For everyone - people, companies, freelancers, scientists...
                                                       </p>
                                                       <p style={{color: "#FFF", fontSize: "16px", marginBottom: "30px", letterSpacing: "1px"}}>
                                                            Get fun, action, dating, know-how, skills, job offers, customers...
                                                       </p>

                                                       <p style={{textTransform: "uppercase", color: "#f2e0be", textAlign: "center", fontSize: "17px", marginBottom: "45px"}}>
                                                            We connect you
                                                       </p>
                                                  </div>
                                                  <div style={{background: "#52393a", padding: "5px 20px", fontSize: "17px", color: "#FFF"}}>
                                                       <b>PENEWO main features - at a glance</b>
                                                  </div>
                                                  <div style={{padding: "20px", color: "#FFF", textAlign: "left", fontSize: "14px"}}>
                                                       <div style={{marginBottom: "10px"}}>
                                                            <div style={{display: "inline-block", width: "20%"}}>Page 1</div>
                                                            <div style={{display: "inline-block"}}>Main Page / Search Results</div>
                                                       </div>
                                                       <div style={{marginBottom: "10px"}}>
                                                            <div style={{display: "inline-block", width: "20%"}}>Page 2</div>
                                                            <div style={{display: "inline-block"}}>Profiles / Penewo Web Pages</div>
                                                       </div>
                                                       <div>
                                                            <div style={{display: "inline-block", width: "20%"}}>Page 3</div>
                                                            <div style={{display: "inline-block"}}>News Feed</div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                             <div>
                                                  <div style={{padding: "30px 0", background: "linear-gradient(#686769 0%, #686769 92%, #FFF 100%)", position: "relative", height: "calc(100vh - 44px)"}}>

                                                       <div style={{padding: "0 45px"}}>
                                                            <div style={{textAlign: "center", marginBottom: "6px"}}>
                                                                 <img src="https://dev.penewo.com/images/logo_thumb_gold.png" style={{maxWidth: "30px"}} />
                                                            </div>
                                                            <div style={{textAlign: "center", color: "#f2e0be", fontSize: "20px", marginBottom: "25px"}}>
                                                                 Peoples <span style={{color: "#c59e78"}}>Net</span>work
                                                            </div>
                                                            <div style={{textAlign: "center", fontSize: "14px", color: "#FFF", textTransform: "uppercase", letterSpacing: "1px", marginBottom: "30px"}}>
                                                                 <p>
                                                                      Main Page / Search Results
                                                                 </p>
                                                                 <p>
                                                                      At a glance
                                                                 </p>
                                                            </div>
                                                            <div style={{marginBottom: "55px"}}>
                                                                 <ul style={{color: "#f2e0be", listStyleType: "square", paddingLeft: "15px", textAlign: "left", fontSize: "14px"}}>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Shows Penewo Page (Profiles) Previews</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Links to Penewo Pages</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Connects to latest uploads</span></li>
                                                                 </ul>
                                                            </div>
                                                       </div>

                                                       <img src="https://dev.penewo.com/images/press-room/mobile-profile.jpg" style={{maxWidth: "100%"}} />
                                                  </div>
                                             </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                             <div>
                                                  <div style={{padding: "30px 0 0", background: "#686769", position: "relative", height: "calc(100vh - 44px)", overflowY: "auto"}}>

                                                       <div style={{padding: "0 35px"}}>
                                                            <div style={{textAlign: "center", marginBottom: "6px"}}>
                                                                 <img src="https://dev.penewo.com/images/logo_thumb_gold.png" style={{maxWidth: "30px"}} />
                                                            </div>
                                                            <div style={{textAlign: "center", color: "#f2e0be", fontSize: "20px", marginBottom: "25px"}}>
                                                                 Peoples <span style={{color: "#c59e78"}}>Net</span>work
                                                            </div>
                                                            <div style={{textAlign: "center", fontSize: "14px", color: "#FFF", textTransform: "uppercase", letterSpacing: "1px", marginBottom: "30px"}}>
                                                                 <p>
                                                                      Profiles / Penewo Webpages
                                                                 </p>
                                                                 <p>
                                                                      At a glance
                                                                 </p>
                                                            </div>
                                                            <div style={{marginBottom: "55px"}}>
                                                                 <ul style={{color: "#f2e0be", listStyleType: "square", paddingLeft: "15px", textAlign: "left", fontSize: "14px"}}>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Comparable to common webpages</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Visible and findable via a search in our webpage directory (a big advantage)</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Connected to News Feed based on similar interests</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Connected to the &quot;Digital Name Card&quot; system</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Connectable to social media</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Incognito Mode, Reviews and more...</span></li>
                                                                 </ul>
                                                            </div>
                                                       </div>

                                                       <img src="https://dev.penewo.com/images/press-room/mobile-profile-full.jpg" style={{width: "100%", maxHeight: "initial"}} />
                                                  </div>
                                             </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                             <div>
                                                  <div style={{padding: "30px 0 0", background: "#686769", position: "relative", height: "calc(100vh - 44px)", overflowY: "auto"}}>

                                                       <div style={{padding: "0 35px"}}>
                                                            <div style={{textAlign: "center", marginBottom: "6px"}}>
                                                                 <img src="https://dev.penewo.com/images/logo_thumb_gold.png" style={{maxWidth: "30px"}} />
                                                            </div>
                                                            <div style={{textAlign: "center", color: "#f2e0be", fontSize: "20px", marginBottom: "25px"}}>
                                                                 Peoples <span style={{color: "#c59e78"}}>Net</span>work
                                                            </div>
                                                            <div style={{textAlign: "center", fontSize: "14px", color: "#FFF", textTransform: "uppercase", letterSpacing: "1px", marginBottom: '30px'}}>
                                                                 <p>
                                                                      News Feed at a Glance
                                                                 </p>
                                                            </div>
                                                            <div style={{marginBottom: "55px"}}>
                                                                 <ul style={{color: "#f2e0be", listStyleType: "square", paddingLeft: "15px", textAlign: "left", fontSize: "14px"}}>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Shows news by predefined interests along with friends and follows</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Various options to link with like-minded people</span></li>
                                                                      <li><span style={{color: "#FFF", marginBottom: "8px", display: "block"}}>Easy on/off settings of the news source</span></li>
                                                                 </ul>
                                                            </div>
                                                       </div>

                                                       <img src="https://dev.penewo.com/images/press-room/mobile-news-feed.jpg" style={{width: "100%", maxHeight: "initial"}} />
                                                  </div>
                                             </div>
                                        </SwiperSlide>
                                   </Swiper>
                              </div>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalWebpageMovie} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>Webpage Movie</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalWebpageMovie(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <video poster="https://dev.penewo.com/press-room-webpage.jpg" controls style={{maxWidth: '100%', maxHeight: '90vh'}}>
                                   <source src="https://dev.penewo.com/press-room-webpage.mp4" type="video/mp4"></source>
                              </video>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalNewsfeedMovie} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>Newsfeed Movie</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalNewsfeedMovie(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: 0, left:0, right:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <video poster="https://dev.penewo.com/press-room-newsfeed.jpg" controls style={{maxWidth: '100%', maxHeight: '90vh'}}>
                                   <source src="https://dev.penewo.com/press-room-newsfeed.mp4" type="video/mp4"></source>
                              </video>
                         </div>
                    </IonModal>

                    <IonModal isOpen={showModalContactUs} showBackdrop={false}>
                         <IonHeader translucent>
                              <IonToolbar>
                                   <div style={{paddingLeft: "10px", fontWeight: "bold"}}>Contact Us</div>
                                   <IonButtons slot="end">
                                        <IonButton onClick={() => setShowModalContactUs(false)}>Close</IonButton>
                                   </IonButtons>
                              </IonToolbar>
                         </IonHeader>
                         <div style={{position: "fixed", top: "60px", left:0, right:0, bottom:0}}>

                              <div style={{marginBottom: "15px", padding: "0 20px", fontSize: "14px"}}>Thank you for contacting us. Please leave your message below and we will get back to you as soon as possible.</div>
                              <IonList style={{width: "100%", padding: "20px"}}>

                                   <IonItem style={{marginLeft: "-20px", marginBottom: 25}}>
                                        <IonLabel position="stacked">Name</IonLabel>
                                        <IonInput></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: "-20px", marginBottom: 25}}>
                                        <IonLabel position="stacked">Email</IonLabel>
                                        <IonInput></IonInput>
                                   </IonItem>

                                   <IonItem style={{marginLeft: "-20px", marginBottom: 5}}>
                                        <IonLabel position="stacked">Message</IonLabel>
                                             <IonTextarea rows={4}></IonTextarea>
                                   </IonItem>

                                   <IonItem style={{marginLeft: "-20px", marginBottom: 5}}>
                                        <button style={{background: 'linear-gradient(to bottom, #5cb85c 0%, #419641 100%)', borderColor: '#3e8f3e', textShadow: '0 -1px 0 rgba(0, 0, 0, .2)', boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)', display: 'block', width: '100%', color: '#FFF', height: 34, fontSize: 14, paddingTop: 6, paddingBottom: 6}}>{(!submitForm) ? "Submit" : <img className="anim-spin" src="./assets/icon/icon-loader.png" style={{maxHeight: '100%'}} />}</button>
                                   </IonItem>
                              </IonList>
                         </div>
                    </IonModal>

               </IonContent>
          </IonPage>
     );
};

export default Pressroom;
